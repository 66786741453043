.pagination {

	display: none;
	@include media($desktop) {
		display: block;
		animation: fadein 2s normal forwards ease-in ;
	}

	position:fixed;
	left: 20px;
	top: 50%;
  	transform: translateY(-50%);

	font-size:1.4em;
	z-index: 10;

	&:before {
		content: '';
		border-right: 1px solid #fff;
		position: absolute;
		top: 0;
		right: 8px; 
		bottom: 0;
		left: 0;
		margin: 0 auto; 
 
	}

}

.pagination li {
	margin-bottom: 0;
	padding: 0;
	display: block;
	// height: 50px;
}

.pagination a {
	display:block;
	height:20px;
	// margin-bottom:5px;
	position:relative;
	padding-right: 15px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 1em;
	color :white;
	.inverted & {color : #000 ;}

}
 
.pagination a .hover-text {
	position:absolute;
	left: 25px;
	font-size: 1em;
	// line-height: 1em;
	top: -.5em;
	font-family: $heading-font-family-italic-cuts; 
	font-weight: normal;
	font-size: .9em;
	// -webkit-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease-in-out;
	width: 220px;
	opacity:0;
	animation: fadein 2s normal forwards ease-in ;

	// display:none;
	@include media($desktop) {
		// display: block;
		opacity: 1;
	}

}

.pagination a.active > .hover-text , 
.pagination a:hover .hover-text {

	@include media($higheres) {
		opacity: 1;
	}
}


.pagination a:after {
	transition: .2s ease all ;
	width:10px;
	height:10px;
	display: block;
	border:1px solid #fff;
	// border-radius:50%;
	transform: rotateZ(45deg);
	content:'';
	position: absolute;
	margin:auto;
	top:0;
	right:4px;
	bottom:0;
	margin: 0;
	background-color: #fff; 
}

.pagination li a:not(.active) {
	&:after {
		transform: rotateX(0deg) ;
		height: 0;
	}
}

.pagination li:hover > .active {
	&:after {
		// background: #000;
	}
}

.pagination li:hover a:after {
	height: 10px;
	background: #fff;
	transform: rotateZ(-225deg) ;
}

.pagination li:last-child a:after ,
.pagination li:first-child a:after {
	height: 10px;
	background: #fff;
	border-radius: 4px;
}

.inverted {

	&.pagination a:after {
		// background-color: #000;
		border-color: #000;
		color: #000;
	}

	&.pagination:before {
		border-color: #000;
	}

	&.pagination li:hover a:after {
		// background: #000;
	}

	&.pagination li:last-child a:after ,
	&.pagination li:first-child a:after {
		background: #000;
	}

	&.pagination li a.active {
		&:after {background-color: #000;}
	}

	span {
		color: #000;
	}

}


span.swiper-pagination-bullet:last-child {
	margin-bottom: 0 !important ;
}

.pagination li:last-child a {
	margin-bottom: -10px;
	padding-bottom: 0;
}


@mixin homePagination($color) {

	// display: none;
    position: fixed;
    left: 2% ;
    top: 27vh;
    // display: flex;
    flex-direction: column;
    color: $color ;
    z-index: 500;
    opacity: 0;

	transition: .5s ease all;

	@include media($higheres) {
		display: flex;
		&.visible {
			opacity: 1;
		}
	}


    &:before {
		content: '';
		border-right: 1px solid $color ;
		position: absolute;
		top: 6px;
		// right: 48%;
		bottom: 0;
		left: 15px;
		margin: 0 auto; 
		height: 95%;
    }

    .swiper-pagination-bullet {
    	width: auto;
    	height: auto;
    	background-color: transparent;
    	margin-bottom: 0.8rem ;
    	font-family: $heading-font-family-italic-cuts ;
    	font-size: 14px;
    	position: relative;
    	padding-left: 2rem;
    	opacity: 1;

    	.pagination-text {
    		opacity: 0;
    		transition: .5s ease all;

    		@include media($higheres) {

			 	&:hover {
			    	opacity: 1;
			    }

			    &.visible {
			    	opacity: 1;
			    }
			}
    	}

	    &:hover {
	    	color: $color ;
	    	opacity: 1;

	    	@include media($higheres) {
		    	.pagination-text {
		    		opacity: 1;
		    	}
		    }
	    }
		&:before {
			opacity: 1;
			content: '';
			left: 0;
			position: relative;
			margin-right: 1rem;
			display: inline-block;
			border: 1px solid $color ;
			padding: 0px 4px;
			width: 5px;
			height: 10px;
			transform: translateX(-16px) translateY(6px) rotateZ(180deg) rotateX(100deg);
			background: $color ;
			margin: 0 auto .5em;
			transition: .5s ease all;
		}

		&:last-child , &:first-child {
			&:before {
				transform: translateX(-16px) translateY(5px);
				border-radius: 10px;
			}
		}

		&.swiper-pagination-bullet-active {
			@include media($higheres) {
				.pagination-text:not(.first) {
	    			opacity: 1;
	    		}
	    	}
		}

		&.swiper-pagination-bullet-active:before,
		&:hover:before {
			transform: translateX(-16px) translateY(6px) rotateZ(45deg);
		}
    }

}


.home-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
	@include homePagination(#fff);
}


.home-pagination.swiper-pagination-clickable.swiper-pagination-bullets.inverted {
	@include homePagination(#000);
}
