ul,
ol {
  // list-style-type: none;
  margin: 0;
  padding: 0;
  // margin-top: 0;
  margin-left: 2em;
  li {
  	margin-bottom: .5em;
  }
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}
