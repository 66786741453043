.four-col {
	.container {
		padding: 3em 0 0; 
		@include clearfix();
	}

	.container > div {
		@include span-columns(12);
		margin: 0;
		padding: 0 1.25em;

		@include media($medium-screen) {
			@include span-columns(6); 
			padding: 0;
		}

		@include media($large-screen) {
			@include span-columns(3); 
			padding: 0;
		}
	}
}