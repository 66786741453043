.three-col {
	.container {
		padding: 3em 0 0; 
		@include clearfix();
	}

	.container > div {
		@include span-columns(12);
		padding: 0 1.25em;

		@include media($large-screen) {
			@include span-columns(4); 
			padding: 0;
		}
	}
}