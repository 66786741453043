figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  display: inline-block;
}

svg {
	path {
		transition: .2s ease fill; 
	}
} 

a object {
	pointer-events: none; 
}

.lazyload {
	opacity: 0;
	transition: opacity 300ms ease ;
}

.lazyloading {
	max-height: 100%;
	opacity: 1;
	transition: opacity 300ms ease; 
}