.blog-list {

	.frame {
		margin-top: 25px;
		padding-top: 50px;
		margin-bottom: 25px;
		padding-bottom: 75px;
		@include media(max-width $large-screen) {
			overflow-x: hidden;
		}
	}


	h1, h1 a {
		text-decoration: none;
	}


		.blog-description {
			// text-align: center;
			margin-bottom: 30px;
			font-size: 16px;
			p {
				font-size: 16px;
			}
			@include media(min-width $large-screen) {
				p {
					font-size: 17px;
				}
					font-size: 17px;
					margin-bottom: 70px;
				}
			> h1 {
				text-align: center;
				font-size: 36px;
				margin-bottom: 30px;
				font-family: $heading-font-family-italic;
				@include media(min-width $large-screen) {
					margin-bottom: 70px;
				}
			}

			.grid-container {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				max-width: 1120px;
				margin: 0 auto;

				.col {
					padding: 1.5em ;
					text-align: left;
					@include media(min-width $large-screen) {
						padding: 0;
						width: calc( 50% - 32px );
						text-align: left;
					}
				}

				.larger > h2 {
					font-family: $base-font-family;
					font-size: 20px;
				}

				.larger > p ,
				.larger p {
					font-family: $heading-font-family;
					@include media(min-width $large-screen) {
						font-size: 26px;
					}
				}

			}

		}


		.blog-filter {
			
			@mixin underline($size, $color, $width, $distance) {
				position: relative;
				&:after {
					top: auto;
					content: '';
					pointer-events: none;
					display: block;
					left: 0;
					right: 0;
					bottom: - #{$distance}px ;
					border-bottom: #{$size}px solid $color;
					width: $width;
					transition: .25s ease all;
					margin: 0 auto;
					position: absolute;
					transform: translateY(#{$size}px);
				};
			} ;

			margin: 0 auto 85px;

			white-space: nowrap;
			width: 150vw;
			
			@include media(max-width $large-screen) {
				box-shadow: 0px 8px 11px 0px rgba(0,0,0,.125) ;
				padding-right: 200px;
			}

			@include media(min-width $large-screen) {
				max-width: 100%;
			}

			ul {
				// overflow: scroll;
				width: 100vw;
				overflow-y: visible;
				@include media(max-width $large-screen) {
					-webkit-overflow-scrolling: touch;
					overflow-x: scroll;
					overflow-y: hidden;
				}

				@include media(max-width $large-screen) {
					width: 100%;
					// min-width: 150vw;
					// max-width: 150vw;
					// overflow-x: scroll;
					// white-space: nowrap;
				}
				@include media(min-width $large-screen) {
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					justify-content: center 
				}
				justify-content: space-between;
				margin-left: 0;

			}

			ul li {
				list-style-type: none;
				display: inline-block;
				// width: 100%;
				width: 150px;
				@include media(min-width $large-screen) {
					// width: 25%;
					max-width: 200px;
				}
			}

			a {
				font-size: 18px;
				text-transform: uppercase;
				text-decoration: none;
				@include underline(5, #fff, 0%, 4);
				color: #383534;
				width: 100%;
				display: block;
				text-align: center;
				position: relative;
				padding: 15px 0 ;
				@include media(min-width $large-screen) {
					padding: 15px 5px ;
				}
			}


			li.active > a {
				color: #383534;
				font-weight: 700 ;
			}

			.active > a, 
			a:hover {
				@include underline(5, #383534, 70px , 6 );
			}

			li:not(:first-child) {
				position: relative;
				&:before {
					pointer-events: none;
					content: '';
					display: block;
					// height: 100%;
					height: 30px;
					border-left: 1px solid #383534;
					position: absolute;
					left: 0;
					right: 0;
					margin: 15px auto 0;
					z-index: 50;
				}
			}


		}


}

.blog, .blog-listing, .blog-list, .blog-cat {
	.pagination {
		@include clearfix();
		display: block;
		position: relative;
		width: 100%;
		flex-wrap: wrap;
		margin: 0 auto 40px;
		padding-left: 1.5sem;

		@include media(max-width $large-screen min-width $small-screen) {
			margin: 60px auto 30px ;
		}

		@include media(min-width $large-screen) {
			box-shadow:0;
			margin: 40px auto 120px;
			padding-left: 0;
		}
		// display: flex;
		// justify-content: unset;
		// flex-direction: row;
		max-width: 1120px;
		position: static;


		&:before {
			display: none;
		}
		a {
			text-transform: uppercase;
			text-decoration: none;
			width: 50%;
			float: left;
			color: #383534;
			text-align: left;
			font-size: 12px;
			padding-right: 10px;
			@include media(min-width $large-screen) {
				padding-right: 15px;
			}
			
			@include media(min-width $large-screen) {
				font-size: 16px;
			}
			img {
				transform-origin: center;
				transition: .25s ease all;
				transform: rotateZ(90deg);
			    margin: .5em 1em -0.3em 0;

				max-width: 2em;
				@include media(min-width $large-screen) {
					max-width: 1em;
					margin: 0;
					transform: rotateZ(90deg);
				}
			}
		}



		a.next,
		a:nth-child(2) {
			text-align: right;
			img {
				// margin: 1em 0;

				margin-left: .75em;
				transform:  rotateZ(270deg);
				@include media(min-width $large-screen) {
					margin: 0;
					transform: rotateZ(270deg);
				}
			}
		}


		a:hover img {
			max-width: 1.1em;
		}

		.disabled {
			visibility: hidden;
		}

	}
}

.blog, .blog-list , .blog-cat  {
		.container.grid-container {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: start ;

			max-width: 1320px;
			margin: 0 auto;
			@include media(min-width $large-screen) {
				margin: 0 auto 80px ;
			}

			> .col {
				position: relative;
				width: calc( 95vw ) ;
				min-height: 430px ;
				background-size: cover;
				background-color: #999;
				position: relative;
				overflow: hidden;
				transition: 1.25s ease-in all ;
				position: relative;
				padding-top: 250px;
				margin: 0 auto 12px;
				// flex: auto ;
				min-height: 360px ;
				@include media(min-width $large-screen) {
					min-height: 430px ;
					width: calc( 33% - 6px ) ;
					margin: 0 4px 12px;
				}

				img {
					width: 100%;
				}

				> h1 {
					position: absolute;
					margin-bottom: 0;
					transition: .25s ease all;
					width: 100%;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					display: flex;
					align-items: center; 
					justify-content: center;
					flex-direction: column;
					color: #fff;
					text-decoration: none;
					padding: 2em;
					text-align: center; 
					font-size: 28px;
					@include media(min-width $large-screen) {
						font-size: 32px;
					}
				}

				&:hover > h1 {
					opacity: 0;
				}

				> .article-preview {
					// margin-top: 50%;
					padding: 24px;
					transition: .75s ease all;
					background-color: #F8F8F8;
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: auto ;
					color: #000;
					// min-height: 480px;
					min-height: 250px;
					margin-bottom: 0;

					h1 {
						padding-top: 14px;
						padding-bottom: 12px;
						line-height: 24px;
					}

					h1 , h1 a {
						text-decoration: none;
					}
					h1 a {
						color: #000;
					}


				}

				&:not(:hover) {
					> .article-preview {
						transform: translateY(480px);
						opacity: 0;
						h1, p {
							transition: 1s ease opacity;
							transition-delay: 2.5s;
						}
					}
				}

				&:focus {
					border: 0;
					outline: 0;
				}

				&:hover {
					> .article-preview {
						opacity: 1;
						transform: translateY(0);
					h1, p {
					
					}
				}

			}


		}
	}
}

.blog-description.container blockquote {
	text-align: left;
	font-size: 16px ;
	font-family: $base-font-family ;
	@include media(min-width $large-screen) {
		font-size: 26px;
		font-family: "Romain Headline Italic Cuts",serif;
		// padding: 0 1.25em 2em;
	}
}

.blog-cat .wide-image {
	// min-height: 540px; 
	padding: 0;
	margin-bottom: 45px;
	@include media(min-width $large-screen) {
	    
		margin-bottom: 90px;
	}
}

.blog .wide-image {
	margin-bottom: 45px;
}


.blog-description .col h1 {
	font-size: 20px;
	font-family: $base-font-family;
}

.blog-cat, .blog {
	header .social {
		display: none;
	}

	header .close {
		display: block;
	}
}