
body.booking  .wrap > .booking-toggle {
	@include media($medium-screen) {
		display: none;
	}
}

.comiseo-daterangepicker {
	transition: .8s ease bottom , 1s ease opacity  ;
}

.booking-form {
	bottom: -100% ; 
	transition: .8s ease bottom , 1s ease opacity  ;
	z-index: 0; 
	width: 100%;
	background: #000 ;
	padding: 1em;
	margin-bottom: 0;
	opacity: 0;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	position: fixed;
	z-index: -20;
	text-align: center ;
	top: 0;
 
	@include media($large-screen) {
		text-align: left;
	}
	@include media($desktop) {
		min-height: 150px;
	}

	&.closed {
		opacity: 0;
		//display: none;
	}
	&.open {
		overflow:hidden;
		top: 0;
		z-index: 5200;
		opacity: 1;
		display: block; 
		bottom: 0;
		overflow: scroll;
		form {
			padding: 2.5em 0 0 0;
			@include media($desktop) {
				min-height: 125px;
				margin-bottom: 0;
				padding-top: 1.5em;
			}
		}
		@include media($desktop) {
			overflow:hidden;
			top: auto;
			form {
				padding: 0;
			}
		}
	}

	h2 {
		margin: .25em auto;
		text-align: center;
		width: 100%;
		font-style: normal;
		font-family: $heading-font-family;
		letter-spacing: .15em;
		@include media($desktop) {
			max-width: 12vw;
			margin: 0;
			text-align: center;
			position: relative;
			top: 0px;
			padding-left: 1em;
		}
	}

	.booking-buttons {
		padding: 1.5em 0 ;
		width: 90%;
		margin: 0 auto; 
		// clear: both ;
		@include clearfix();
		text-align: center;

		@include media($large-screen) {
			text-align: center;
			margin: 0 auto ;
			padding:0;
			// width: 70%;
			float: left;
			width: 100%;
		}

		@include media($desktop) {
			text-align: right;
			margin: 0 auto;
			padding:0 1em;
			// width: 490px;
			// float: right;
			// margin-left: -2em;
			max-width: 420px;
		}


		a.button {
			font-family: $base-font-family; 
			background: $medium-gray; 
			color: #fff;
			display: inline-block;
			width: 100%;
			padding: 1em 0;
			color: #fff;
			font-size: 12px;
			text-align: center; 
			border: 0;
			margin: 0 0 1em;

			&.loading {
				@include loadingAnimation();
			}
			&.loading:not(:hover):after {
				background-image: url('images/loader-dark.svg');
			}

			@include media($large-screen) {
				max-width: 350px;
				margin: 2em 1.5em 2em;
			}
			@include media($desktop) {
				margin: 0 1.5em;
				max-width: 350px;

			}
			&:hover {
				color: #000;
				cursor: pointer;
				&:before {
					background: $offwhite;
				}
			}
			@include media($desktop) {
				display: inline-block;
				/*max-width: 48% ;*/
				margin: 0;
				/*float: left;*/
				width: 100%;
				/*margin-left: 4%;*/
				/*&:first-of-type(){
					margin-left: 10PX;
				}*/
			}
		}

		a.button:first-of-type {
			background-color: $offwhite;
			min-width: 300px;
			color: #000;
			&:before {
				background-color: #000;
				display: none;
			}
			&:hover {
				// background-color: #000;
				background-color: $offwhite;
			}
			&:active, &:hover {
				color: #fff;
				background-color: $medium-gray; 
			}
		}

	}

	button.close {
		position: absolute;
		right: .25em;
		top: .25em;
		width: 30px;
		height: 30px;
		display: block;
	}

	& > form {
		align-items: center ;
		border: 1px solid #fff;
		min-height: 95vh;
		@include media($desktop) {
			min-height: 0;
			display: flex;
			align-items: bottom;
			justify-content:space-between;
			// padding-right: 50px;
		}
	}

	& > form > fieldset {
		display: block;
		width: 90%;
		margin: 2.5em auto;
		max-width: 57em;
		border-top: 1px solid rgba(255,255,255,0.4);
		@include media($desktop) {
			border-top: 1px solid rgba(255,255,255,0.4);
			margin: 0 ;
			max-width: 1180px;
			width: 100%;
			border: 0;
			align-items: center;
			text-align: center;
			justify-content: space-around;
		}
	}

	& > form > fieldset > label  {
		margin:0;
		width: 100%;
		position: relative;
		padding: 11px 5px 0;
		border-bottom: 1px solid rgba(255,255,255,0.4);
		min-height: 7em;
		
		&:not(.dates) {
			max-width: 50%; 
			float: left;
			
			@include media($large-screen) {
				max-width: 25%;
			}
			@include media($desktop) {
				max-width: 17%;
			}
			@include media($hires) {
				/*max-width: 134px;*/
				
				&:nth-of-type(3) {
					
					
					&:after {
						left: -25px;
					}
				}
			}
		}
		
		@include media($desktop) {
			min-height: 30vw;
		}
		
		&:nth-child(2n+3) {
			border-left: 1px solid rgba(255,255,255,0.4);
			@include media($desktop) {
				border: 0;
			}
		}
 	
		@include media($large-screen) {
			text-align: center;
			&:nth-child(4n+4) {
				border-left: 1px solid rgba(255,255,255,0.4);
			}
		}

		@include media($desktop) { 
			display: inline-block;
			max-width: 15%;
			float: left;
			padding: 0 5px 0 ;
			min-height: 0;
			border: 0;
			&:nth-child(4n+4) {
				border: 0;
			}
		}
	}

	& > form > fieldset > label.dates {

		span {
			display: block;
		}

		label , input {
			display: inline-block;
		}

		.ui-button .ui-icon {
			//background: url('../assets/images/icon-booking-bar-date-chevron.svg');
			width: 0px;
			height: 0px;
		}

		.comiseo-daterangepicker-triggerbutton .ui-button-icon {
			margin-top: -15px;
		}

		button , a {
			border: 0;
			display: block; 
			background-color: transparent;
			color: #fff;
			text-decoration: none;
			font-size: 1.65em;
			font-family: "Romain Headline",serif;
			margin-bottom: 0;
			margin-top: -5px;
			text-transform: none;
			max-width: 250px;
			min-width: 0;
			padding-top: .25em;
		}


		@include media($desktop) {
			max-width: 25%;
			position: relative;
			top: 2px;
		}
	}

	& > form > fieldset > label:nth-of-type(3),
	& > form > fieldset > label:first-of-type {
		@include media($desktop) { 
			margin-left: 15px;
		}
		&:after {
			@include media($desktop) { 
				top: 16px;
				content: '';
				left: -9px;
			}
			height: 30px;
			border-right: 1px solid #A8A8A8;
			position: absolute;
			left: 0;
			width: 0;
			top: 23px;
		}
	}
	& > form > fieldset > label:nth-of-type(1) {
		&:after { border: none;  }
	}
	
	& > form > fieldset > label:first-of-type:after {
		left: 3px;
	}

	& > form > fieldset > label > input, & > form > fieldset > label > .input {
		background: transparent;
		color: #fff;
		border: 0;
		text-align: center;
		margin:0;
		margin-top: 7px;
		padding:0;
		font-size: 2.2em;
		letter-spacing: .15em;
		font-weight: normal;
		font-family: $heading-font-family;
		@include media($large-screen) {
			position: relative;
			top: -5px;
		}
		@include media($desktop) {
			font-size: 2em;
		}
		&[type=number]::-webkit-inner-spin-button, 
		&[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		
		&:focus {
			box-shadow: none;
		}
	}

	& > form > fieldset > label > span {
		color: #A8A8A8 ;
		pointer-events: none ;
		letter-spacing: .09em;
		font-size: .95em;
	}



}

.ui-widget .comiseo-daterangepicker-main {

	.availablity-button {
		background: #fff;
		font-size: 1em;
	}

	p.booking-bar-unavailable {
		text-align:center;
	}
}


.field-group > label > a.spinner  ,
.booking-form label > a.spinner {
	background: transparent;
	position: absolute;
	bottom: 12px;
	margin-bottom: 0; 
	margin-top: 3px;
    width: 15px;
    height: 45px;
	top: 33%;
	z-index: 50;
	opacity: .85;
	
	&:hover {
		opacity: 1;
		cursor: pointer; 
	}
	
	&.minus {
		left: 1em;
		background: url('images/icon-spinner.svg') no-repeat center center;
		// content: url('images/icon-chevron-left.svg');
	}
	&.plus {
		right: 1em;
		background: url('images/icon-spinner.svg') no-repeat center center;
		transform: rotateZ(180deg);
	}
}

.booking-form label > a.spinner {
    width: 29px;
	top: 38%;
	
	@include media($desktop) {
		top: 30%;
	}
	
	&.minus, &.plus {
		&:before {

			border-color: #fff;
			border-style: solid;
			border-width: 2px 2px 0 0;
			content: '';
			display: inline-block;
			height: 18px;
			position: relative;
			top: 0.15em;
			transform: rotate(-45deg);
			vertical-align: top;
			width: 18px;
			top: 28%;
		}
	}

	&.disabled {
		&:before {
			border-color: #8b8a89;
		}
	}

	&.minus {
		background: none;
		@include media($desktop) {
			left: 50%;
			margin-left: -55.5px;
		}
		
		&:before {
			left: .35em;
			transform: rotate(-135deg);
		}
	}
	&.plus {
		background: none;
		transform: none;
		@include media($desktop) {
			right: 50%;
			margin-right: -55.5px;
		}
		&:before {
			left: -.35em;
			transform: rotate(45deg);
		}
	}
}

.booking-form label.date:before {
	// content: '';
	// background: url('images/icon-date-arrow.png');
	position: absolute;
	right: 0px ;
	bottom: 18%;
	width: 25px;
	height: 25px;
	background-size: cover; 
	pointer-events: none; 
}


.booking-form label {
	// input::-ms-clear,
	input::-webkit-clear-button,
	input::-webkit-inner-spin-button,
	input::-webkit-calendar-picker-indicator {
	    display: none;
	    -webkit-appearance: none;
	}
}


.booking-form .close {
	@include close-button();
	display: block;
	top: 2em;
	right: 2em;
	position: absolute;
	
	@include media($desktop) {
		top: 1.5em;
	}
}

.temp-hidden {
	display: none; 
}