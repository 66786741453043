.quote-slider {
	text-align: center;
	width: 100%;
	margin-bottom: 6em;
	background: $offwhite;
	color: #383534; 
	padding: 3em 0 6em;  
	@include media($large-screen) {
		padding: 6em 0  ;  
	}
	.swiper-slide .description  {
		font-family: $heading-font-family-italic;
		padding: 2.25em 1.25em 1.5em;  
		position: relative;
		margin: .5em auto 1.25em;
		max-width: 1000px;

		font-size: 1.4em;
		line-height: 1.75em;
		@include media($large-screen) {
			font-size: 1.8em;
			line-height: 1.575em;
		}

		@include media($large-screen) {
			padding: 0 ;
		}
		&:before, &:after {
	
			background-image: url('images/quotemark.svg');
			background-repeat: no-repeat;
			content: '';
			background-size: contain;
			display: block;
			position: absolute;
			left: 15px;
			top: -5px;
			opacity: .65;
			width: 30px;
			height: 30px;
			@include media($large-screen) {
				width: 50px;
				height: 40px;
				opacity: .7;
				top: 0px;
				left: -85px;
			}
		}
		&:after {
			// bottom: 10px;
			right: 15px; 
			@include media($large-screen) {
				bottom: 0;
				right: -85px ;
			}
			top: auto;
			left: auto;
			transform: rotateZ(180deg);
		}

	}

	strong {
		text-transform: uppercase;
		color: #383534 ; 
		font-weight: normal;
		font-family: $base-font-family;
		@include media($large-screen) {
			font-size: 1.25em;
			// line-height: 66px;
		}
	}

	

}