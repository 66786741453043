.rooms {
	padding: 0 0 8em ;
	margin: 0;
	background: $light-gray;
	text-align: center;

	.rooms-gallery {
		width: 100vw;
		// min-height: 100vh ;
		padding: 0;
		margin: 0;
		@include outer-container(100vw);
		background: #fff; 
		overflow-x: hidden; 

		> .room {

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0; 
				background: #000;
				z-index: 25;
				opacity: .5;
				transition: .2s ease all; 
			}

			&:hover:before {
				opacity: .25;
			}

			float: left;
			width: 100%; 
			clear: both; 
			background-position: center center; 
			position: relative;			
			@include padding(5em null);
			margin-bottom: 2.5vw ;
			text-align: center;
			color: #fff; 
			background-size: cover ;
			transition: .2s ease all; 
			text-decoration: none; 

			@include media($medium-screen) {
				margin-bottom: .25em;
				width: 49.85vw;
				height: 25vw;
				float: left;
				clear: none;
				display: flex;
				align-items: center; 
				margin-right: .25vw;
				@include omega(2n);
			}

			@include media($medium-screen) {
				// height: 23vw;
			}

			&:hover {
				// transform: scale(1.05);
			}

			div {
				margin: 0 auto;
				@include media($medium-screen) {
					max-width: 85%; 
				}
			}

			p , a , span {
				display: block; 
				position: relative;
				z-index: 45;
			}

			a {
				display: inline-block;
			}

			p {
				
				font-family: $heading-font-family-italic-cuts;
				// font-style: italic; 
				font-size: 1.4em;
				@include media($large-screen) {
					font-size: 2em;
					// padding-top: 1.25em;
				}
			}
		}
	}
}

.rooms .section-heading {
	font-size: 1.4em;
	@include media($large-screen) {
		font-size: 2em;
		margin-bottom: 0;
	}
}

.rooms-gallery .room .button {
	font-family: $base-font-family; 
	font-weight: normal;
	font-size: .95em; 
	padding: .5em 3.25em; 
}

.rooms-gallery .room  > div a.button {
	@extend .hvr-sweep-to-right; 
	border: 0;
	border: 1px solid #fff;
	outline: 0;
}
