
.wide-image + .two-col {
    @include media($large-screen) {
    	padding-top: 4em;
    }
}

.wide-image + .two-col {
	// margin-top: 2em;
}

.wide-image:first-child + .two-col , 
.page-meetings .wide-image + .two-col {
	 @include media($large-screen) {
	 	margin-top: 1em;
	 	margin-bottom: 1em; 
	 }
}

@include media(max-width $medium-screen) {
	.two-col + .two-col {
		padding-top: 0;
		> .container {
			padding-top: 0;
		}
	}
}

.two-col {

	padding: 1.5em  ;

	@include media($large-screen) {
		padding: 2em 1.5em 2em ;
		
	}

	// margin: 3em auto  ;

	h2 {
		margin-bottom: 1.25em;
	}

	h2.mid {
		font-size: 1.5em;
	}

	li {
		
		font-size: 1.25em;
		line-height: 1.5em;

		@include media($medium-screen) {
			font-size: 1.6em;
			line-height: 1.5em; 
		}
	}

	p {
		margin-bottom: 1.25em;
	}

	li {
		font-size: 14.4px; 
		@include media($medium-screen) {
			font-size: 1em; 
		}
	}
 
	ul {
		margin-top: .5em;
		margin-bottom: 1em;
	}

	strong {
		font-weight: bold;
		font-family: 'AvenirNextLTPro-Demi' ;
	}

	img {
		max-width: 100%;
		height: auto;
		margin-bottom: 0;
	}

	.container {
		clear: both;
		float: none;
		max-width: $max-width;
		padding: 2.5em 0 0;
		@include media($large-screen) {
			padding: 3.25em 0 0;

		}
		&:after {
			display: block;
			content:'';
			clear: both;
		}
	}

	.container:first-child {
		// margin-bottom: 2em;
	}

	.container > h2 {
		text-align: center;
		margin-bottom: .65em;
	}

	main h2 {
		font-size:  1.25em ;
		line-height: 1.5em;
		border-bottom: 1px solid $medium-gray;
		padding: .5em 0;
		margin-bottom: 1.25em;
	}

	.main {


		@include span-columns(12);
		margin-bottom: 2em;
		@include media($large-screen) {
			@include span-columns(6); 
			padding-right: 1.5em;
		}
	}

	&.first-char .main p:first-of-type::first-letter {
		font-size: 2em;
	}

	&.image-text {
		.sidebar {
			order: -1;
		}
	}

	.sidebar {
		
		@media screen and (max-width: 768px){
			width: 100%;
			margin-bottom: 2em;
		}
		@include media($large-screen) {
			@include span-columns(6); 
		}
	}
}


.two-col .container {

	display: flex ;
	flex-direcetion: row;
	flex-wrap: wrap ;

	@include media($large-screen) {
		display: block ;
	}

	.sidebar {

	}


	main, .sidebar {

		h1 {
			font-size: 1.65em;
		}

		h3 {
			font-size: 1.25em;
		}

	}
}

.two-col main h3:first-child {
	font-size: 1.15em;
	@include media($medium-screen) {
		font-size: 1.6em;
	}
}

.two-col.has-form {
	margin-top: 0;
	@include media($medium-screen) {
		margin: 4em auto;
	}
}


.two-col.line-footer {

	padding-top: 3.25em;

	&:after {

		position: absolute;
		left: 0;
		right: 0;
		max-width: 85vw;
		height: 5px;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		// top: -15px;
		margin: 0 auto ;
		bottom: 25px ;

		@include media($large-screen) {
			display: none;
		}
	}
}