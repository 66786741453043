// Code here
//28/04/2021
@import 'jquery-ui.css';
@import 'jquery.comiseo.daterangepicker.css' ;
@import 'countrySelect.css' ;
@import 'intlTelInput.css' ;
@import 'select2.css' ;
@import 'protel-wbe.css' ;
@import 'magnific-popup.scss' ;

@import "bourbon/bourbon";

@import "neat/neat";


@mixin loadingAnimation() {


	&:after {
	    background: url(images/loader.svg) no-repeat center center;
	    content: '';
	    position: absolute;
	    display: inline-block;
	    transform: translateX(5px) translateY(-1px) scale(.85);
	    height: 2rem;
	    width: 2rem;
	}

}			


@import "base/base";

@import "layout/layout" ;
@import "modules/modules" ;


a:focus, a:active  {
	outline-color: transparent;
}

body.menu-open {
	overflow: hidden;
}

body.home {

	scrollbar-width: none;
	

	&::-webkit-scrollbar {
	display: none;
	}

	-ms-overflow-style: none; 
	overflow: -moz-scrollbars-none;

}

body, html {
	&.noscroll .wrap {
		pointer-events: none;
		overflow: hidden;
		height: 100vh;
	}

	&.home > .wrap.at-footer {
		padding-bottom: 35px;
	}




}

.trans {
	opacity: 0;
	animation-name: none;
	pointer-events: none; 
	object, img, a {
		opacity: 0;
	}
}

@keyframes fadein {
		from {
				opacity: 0;
				// transform: scale(.85);
		}

		to {      
				opacity: 1;
				// transform: scale(1);
		}
}

@mixin placeholder {
	::-webkit-input-placeholder {@content}
	:-moz-placeholder           {@content}
	::-moz-placeholder          {@content}
	:-ms-input-placeholder      {@content} 
}

div.blockUI.blockMsg.blockPage {
	cursor: auto !important;
	padding: 20px !important;

	span.button.b-close {
		position: absolute;
		top: 0px;
		right: 0px;
		cursor:pointer;
		border: none;
		padding: 0px;
		width: 35px;
	}
}

div.blockUI.blockOverlay {
	cursor: auto !important;
}


.swiper-container {
	height: 100%;
	width: 100%;
}


.home-swiper > .swiper-wrapper {
	display: block ;
}

.home-swiper > .swiper-wrapper > .swiper-slide {
  	width: 100%;
  	height: 100vh;
  	max-height: 100vh;
  	min-height: 100vh;
 	overflow: hidden;
}


.wrap.clamped {
	overflow: hidden;
	height: 100vh;
}