header {
	width: 100vw ;
	display: block; 
	position: fixed; 
	top: 0;
	left: auto;
	right: auto ;
	z-index: 16000;
	background: transparent; 
	height: 60px;
	@include media($medium-screen) {
		// padding-top: .5em;
		// height: 60px;
		
	}

	.blog & ,
	.blog-list & ,
	.blog-cat & ,
	.inner-page.modal-open &,
	.inner-page & {
		padding-top: 1em;
		// padding-top: .5em; 
		position: fixed;
		position: sticky;
		padding-top: 0;
		top: 0;
		margin-bottom: 0;
		background: $medium-gray;
		// background: #fca;
		@extend .inverse; 

		.nav {
			// padding-top: 1em;
		}

		.social {
			display: none;
		}

		.return-x {
			display: block;
		}
	}

}


header a {
	
	&:active, &:focus {
		outline: 0;
		border: none;
		-moz-outline-style: none
	}

}

.home header .nav {
	@include media($medium-screen) {
		margin-top: -.15em;
	}
}

header li.nav {
	margin-top: 0;
}

header .menu-nav-trigger {
	display: inline-block;
	height: 40px;
	float: left;
	width: 50px;
	@include media($medium-screen) {
		width: 80px;
		position: relative;
		top: 9px;
		max-width: 100%;
	}

	.inner-page & , .modal-open & {
		// margin-top: .25em;
	}

}

header .menu-nav-icon {
	@include media($medium-screen) {
		width: 50px;
		min-height: 50px;
		animation: 3s ease 0s normal forwards 1 fadein;
	}
	width: 0px;
	height: 24px; 
	display: block ;
	z-index: 1500;
	position: relative;

	> .inner {
		background-color: #fff;
		top: 20px;
		@include media($medium-screen) {
			top: 15px;
			width: 34px;
		}
		transition: $base-animation;
		position: absolute;
		width: 25px;
		height: 2px;
		margin-top: 5px; 

		&:after, &:before {
			background-color: #fff; 
			display: block;
			content: '';
		    position: absolute;
		    width: 25px;
		    height: 2px;
		    transition: $base-animation; 
		    border-radius: 4px;
		    top: 7px; 
		    @include media($medium-screen) {
				top: 10px;
				width: 34px;
			}

		}

		&:before {
			top: -7px;
			@include media($medium-screen) {
				top: -10px;
			}
		}

	}


	&.is-open > span.inner {
		background-color: transparent;
		&:before {
			transform: translate3d(0,7px,0)   rotate(45deg);
			background-color: #fff;
			@include media($medium-screen) {
				transform: translate3d(0,10px,0)   rotate(45deg);
			}
		}
		&:after {
			transform: translate3d(0,-7px,0)   rotate(-45deg);
			background-color: #fff;
			@include media($medium-screen) {
				transform: translate3d(0,-10px,0)   rotate(-45deg);
			}
		}
	}

	&.close-button > span.inner {
		background-color: transparent;
		&:before {
			transform: translate3d(0,7px,0)   rotate(45deg);
			background-color: #fff;
			@include media($medium-screen) {
				transform: translate3d(0,10px,0)   rotate(45deg);
			}
		}
		&:after {
			transform: translate3d(0,-7px,0)   rotate(-45deg);
			background-color: #fff;
			@include media($medium-screen) {
				transform: translate3d(0,-10px,0)   rotate(-45deg);
			}
		}
	}

}


body.modal-open header .menu-nav-icon.is-open > span.inner,  body.modal-open header .menu-nav-icon.close-button > span.inner {
	background-color:  transparent;
}

body.modal-open header .menu-nav-icon > .inner {
	color: #fff;
	background-color: #fff; 
	@include media($medium-screen) {
		top: 18px;
	}
	&:after,&:before {
		background-color: #fff;
	}
}
header.inverted .menu-nav-icon > .inner {
	background-color: #000;
	&:after,&:before {
		background-color: #000;
	}
}

header.inverted .is-open .menu-nav-icon > .inner {
	background-color: transparent;
}

header ul {
	max-width: 100% ;
	margin:  0;
	padding: .25em 1em 0;
	@include media($medium-screen) {
		padding: 0 2em 0 1em;
	}
	display: flex;
	justify-content: center;
	
	li {
		max-height: 60px;
		transition: $base-animation; 
		min-width: 33.33%;
		text-align: center; 
		list-style-type: none;
		margin-bottom: 0;
		margin-top: 0;
		@include media($medium-screen) {
			margin-top: .5em;
			// max-height: 60px;
		}
	}

	li img {
		display: inline-block;
	}
}

header .branding {
	transition: .5s ease opacity , 1s ease transform ; 
	text-align: center;
	margin-bottom: 0;
	z-index: 400;

	a {

		.inner-page & , .modal-open & {
			max-width: 230px;
			height: 80px;
			object {
				margin-top: 1.5em;
				max-height: 25px;
				@include media($medium-screen) {
					margin-top: .9em;
				}
			}
		}

		cursor: pointer;
		width: 100%;
		height: 80px;
		display: block ;
		transition: .2s ease all; 
		&:hover {
			// transform: scale(1.01);
		}
	}

	object, img  {
		max-height: 60px;
		margin-top: 1.5em;
		@include media($medium-screen) {
			max-height: 30px;
			max-width: auto;
			margin: .65em 0 0;

			.home & {
				height: 110px;
				width: auto;
				max-height: none;
				margin:0;
			}
			.blog-list &,
			.blog-cat &,
			.modal-open & ,
			.inner-page & {
				width: 100%;
			}


		}
	}

	&.logo-pulled {
		// transform: translateY(-250px);
	}

}

header.at-footer {
	.branding {
		visibility: hidden;
	}
}

header .social {
	text-align: right;

	@include media($medium-screen) {
		// animation: 3s ease 0s normal forwards 1 fadein;
	}

	a {
		height: 45px;
		width: 45px;
		display: block; 
		float: right;
	}

	object, img {
		display: none;
		transition: $base-animation;
		max-width: 40px;
		float: right;
		margin-left: 10px;
		@include media($medium-screen) {
			display: inline-block;
		    

				&:hover {
					opacity: .5;
				}
		}
	}
}


header.inverted object {
	.cls-1 {
		fill: #000;
	}
}

header .close {
	z-index: 1500;
	top: 0;
	position: relative;
	display: none;


	a {
		float:right;
	
		.menu-nav-icon {
			margin-right: 25px;

			@include media($medium-screen) {
				margin-right: 35px;
			}
		}
	}

	.blog-list  & , .blog-cat & , 
	.inner-page & , .modal-open & {
	display: inline-block; 
	text-align: right;
		a, img {
			max-width: 100%;
			height: auto;
		}

		img {
			display: inline-block;
			max-width: 22px;
			@include media($medium-screen) {
				max-width: 28px;
			}
		}

	}
 
}

.modal-open header .branding,  
.inner-page header .branding {
	a  {
		max-width: 230px;
		margin: 0 auto;
	}
}