body .wrap:before {
	// transform: translate3d(0, 0, 0) ;
	position: fixed  ;
	transition: 1.25s ease transform ;
	top: 0 ;
	content: '';
	// left: -100vw;
	right: 0;
	bottom: 0;
	height: 60px;
	z-index: 1000;
	width: 100vw ;
	background-color: $medium-gray ;
	transform: translateX(-100vw);
	will-change: transform;
	display:none; 
}


body.modal-closed .wrap:before {
	width: 100vw;
}

body.modal-open .wrap:before {
	width: 100vw;
	left: 0;
	transform: translateX(0);
}



body.modal-open {
	overflow: hidden;
}



body.modal-open header {


	.branding {
		// opacity: 1 !important ;
		z-index: 500;

		&.trans  {
			a,
			object {
				opacity: 1 !important ;
			}
		}

	}
	.close {
		z-index: 500;
		@include media($medium-screen) {
		}
	}

	.social {
		display: none;
	}
}

#modal {
	opacity: 0;
	z-index: 5000;
	background-color: #fff;
	min-height: 110vh;
	position: fixed;
	transform: translate(0,100vh);
	transition: 1.25s ease  transform ; 
	top: 60px;
	width: 100%; 
	will-change: transform; 
	.modal-open & {
		display: block;
		opacity: 1;
	}

	.modal-reset & {
		transform: translate(0,0);
	}

}


#modal > .modal-content {
	opacity: 0;
	transition: 1.255s ease opacity;
	transition-delay: 2.5s ; 
}

.modal-open #modal > .modal-content {
	opacity: 1; 
	height: 100vh;
	overflow: scroll;
	section {
		// opacity: 0;
	}
}

.overlay {
	transform: translate(0,50vw) ;
	opacity: 0;
	display:none;
	transition: .25s opacity, .25s transform  ; 
}

.modal-open .overlay {
	opacity: 1;
	background: #fff url('images/loading.svg') no-repeat center center;
	background-size: 240px;
	z-index: 125;
	top: 0;
	bottom:0;
	width: 100%; 
	height: 101vh;
	position: fixed;
	transform: translate(0,0) ;
	will-change: transform ; 
	display: block ;
}



#modal section > *:not(.card) {
	
	transition-delay: 2.5s;
	transition: 1.25s ease opacity;
}


#modal section .card {
	transition-property: all ;
	transition-duration: 1.25s;
	transition-delay: 1.25s;
	opacity: 0;
    white-space: nowrap; 
    text-overflow: clip; 
	* {
		opacity: 0;
		transition: 1.5s ease opacity; 
		transition-delay: 1.5s;
	}
}

#modal.modal-open section > .card * {
	// opacity: 1;
}

#modal.modal-open .card {
	// opacity: 1;
// 	width: 100%;
// 	max-width: 420px;
	transition-delay: 1s;
}


#modal.modal-open section > *:not(.card) {
	// opacity: 1;
}


