.pullquote {
	// font-family: $heading-font-family; 
	text-align: center;
	padding: 2em 1.5em 6em;


	blockquote {
		font-style: initial;
		font-family: $heading-font-family-italic-cuts ;
		// font-style: italic;
		font-size: 1.25em;
		margin:0 auto 3em;
		width: 100%;
		padding: 0 1.25em 2em;
		max-width: 930px ;
		@include media($large-screen) {
			margin: 0em auto 2.25em;
			padding:0;
			font-size: 1.8em;
		}
	}

}