.gallery-slider {
	max-width: $max-width;
	margin: 3.5em auto 6em;
	overflow: visible;

	.caption {
		text-align: center;
		max-width:  calc( #{$medium-screen} / .85 ) ;
		margin: 0 auto ;
		padding: 1em 2.5em 40px;
		@include media($large-screen) {
			padding: 1em 0 ;
		}
	}

	.swiper-container {
		padding-bottom: 20px;
		.swiper-pagination {
			@include media($large-screen) {
				// bottom: -40px;
				transform: translateY(1em);
				bottom: 5px; 
			}
		}

		.swiper-pagination-bullet {
			border-radius: 0;
			background-color: #fff;
			border: 1px solid #000;
			transform: rotateZ(45deg);
			height: 12px;
			width: 12px;
			margin: 0 .25em;
			&.swiper-pagination-bullet-active {
				background: #000;
			}
		}
	}

	.swiper-button-prev, .swiper-button-next {
		top: auto;
		z-index: 250;
		background-image: url('images/icon-chevron-circle.svg');
		background-size: cover ;
		margin-bottom: 0;
		bottom: 45%; 
		width: 30px;
		height: 30px;
		display: none;
		@include media($large-screen) {
			display: block;
			bottom: 4em;
			width: 50px;
			height: 50px;
		}

		&.swiper-button-prev {
			transform: rotateZ(0deg);
		}
		&.swiper-button-next {
			transform: rotateZ(180deg);
		}
	}

}

.gallery-slider .card {
	margin-top: 6em;
	bottom: auto;
	top: -3em;
	max-width: 75vw;
	@include media($large-screen) {
		max-width: 385px;
	}	
}

.caption {
	transition: .85s ease all;
	opacity: 0;
	font-size: 1.1em;
	line-height: 1.5em ; 
	@include media($medium-screen) {
		font-size: 1.1em;
		line-height: 1.5em; 
	}
	.swiper-slide-active & {
		opacity: 1;
	}
}