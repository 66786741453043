.amenities {
	max-width: 85vw; 
	margin: 0 auto 1.25em;

	.container {
		padding: 3em 0;
	}

	h2 {
		position: absolute;
		left: 0;
		right: 0;
		top: 1.65em;
		text-align: center;
		@include media($large-screen) {
			top: 1.35em;
			display: inline-block;
			font-size: 1.5em;
		}
		span {
			background: #fff; 
			font-style: italic;
			@include padding(null 3.5em);
		}
	}

	ul {
		border: 1px solid #000;
		@include clearfix();
		padding: 3em 2em 2em 2em;
		margin: 0;

		li {
			font-size: 1.125em;
			text-align: left; 
			list-style-type: none;
			padding: 0 0 0 1.5em;
			@include media($medium-screen) {
				padding: 0 0 0 2.5em;
			}
			@include media(min-width $medium-screen max-width  $large-screen) {
				padding:0;
				@include span-columns(4);
				@include omega(3n);
				margin-bottom: 1.25em;
			}
			@include media($large-screen) {
				@include span-columns(3);
				@include omega(4n);
			}
		}
	}
}

.amenities .amenity {

	display: flex;
	justify-content: center ; 
	align-items: center; 
	max-height: 45px;
	min-height: 45px;

	&:before {
		content: '';
		display: inline-block;
		width: 35px;
		height: 35px;
		vertical-align: middle;
		background: no-repeat center center; 
		margin-right: 2em;
		background-size: contain;  
		display: none;
	}

	img {
		margin-right: 2em;
		vertical-align: middle;
		// display: inline-block;
		max-width: 28px;
		max-height: 40px;
		height: auto; 
	}

	span {
		margin-bottom: 0;
		text-align: left;
		display: inline-block;
		vertical-align: middle;
	    max-width: 100%;
    	width: 65%;
	}

	&.guests img {
		max-width: 36px;
	}
	&.beds img {
		max-width: 28px;
	}
	&.parking img {
		max-width: 25px;
	}
	&.lounges img {
		max-width: 45px;
	}
	&.wifi img {
		max-width: 22px;
	}
	&.ensuite img {
		max-width: 43px;
	}
	&.safe img {
		max-width: 17px;
	}
	&.restaurant img {
		max-width: 15px;
	}
	&.area img {
		max-width: 13px;
	}
	&.concierge img {
		max-width: 25px;
	}
	&.valet img {
		max-width: 25px;
	}
	&.bar img {
		max-width: 20px;
	}

	&.guests:before {
		background-image : url('images/icon-guests.svg');
		background-size: 36px ; 
	}
	&.beds:before {
		background-image : url('images/icon-bed.svg');
		background-size: 28px 28px;
	}	
	&.parking:before {
		background-image : url('images/icon-parking.svg');
		background-size: 25px 25px;
	}
	&.lounges:before {
		background-image : url('images/icon-lounges.svg');
		background-size: 45px
	}
	&.wifi:before {
		background-image : url('images/icon-wifi.svg');
		background-size: 22px ;
	}
	&.ensuite:before {
		background-image : url('images/icon-ensuite.svg');
		background-size: 43px;
	}
	&.safe:before {
		background-image : url('images/icon-safe.svg');
		background-size: 17px;
	}
	&.restaurant:before {
		background-image : url('images/icon-restaurant.svg');
		background-size: 15px 29px;
	}
	&.area:before {
		background-image : url('images/icon-area.svg');
		background-size: 13px;
	}
	&.concierge:before {
		background-image : url('images/icon-concierge.svg');
		background-size: 25px 20px;
	}
	&.valet:before {
		background-image : url('images/icon-valet.svg');
		background-size: 25px 25px;
	}
	&.bar:before {
		background-image : url('images/icon-bar.svg');
		background-size: 20px 26px;
	}

}

