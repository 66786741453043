section.slider h1.section-heading {
	font-family: $heading-font-family-italic-cuts ;
	font-style: initial;
}

.slider {
	min-height: 100vh !important;
	text-align: center; 
	@include media($medium-screen) {
		min-height: 120vh;
		background-size: cover ;
		display: flex;
		align-items: center; 
	}

	.swiper-container {
		overflow: visible;
		@include media($medium-screen) {
			margin-top: 10vw;
		}
		@include media($large-screen) {
			margin-top: 5vw;
		}
	}

	.swiper-wrapper {
		@include media($medium-screen) {
			margin-top: 6.5em;
		}		
		@include media($large-screen) {
			margin-top: 3.5em;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center; 
		}
	}

	 .slides {
	 	width: 100%; 
	 	@include clearfix();
	 	padding: 25vw 0 1em;
	 	@include media($medium-screen) {
	 		padding: 6.5em 1.5em 2em;
 		    min-height: 95vw;
	 	}

	 	@include media($large-screen) {
	 		padding: 8em 0 2em;
	 	}
	 	@include media($desktop) {
	 		min-height: 0;
	 		padding: 0 0 8em;
	 	}

	 }

	.slide-container {
		padding-top: 1.5em;
		@include media($medium-screen) {
			padding-top: 3em;
			@include outer-container();
			margin-bottom: 0;
		}
	}

	.slide {

		margin-top: 1em;
		@include media($medium-screen) {
			margin: 0;
		}

		.image-wrap {
			text-align: center; 
			display: block ;
			padding: 1em 3.5em 0;
			@include media($medium-screen) {
				padding:0;
				margin-bottom: 2em;
			}

			max-width: 100%;
			margin: 0 auto 1.5em ;

			@include media(min-width $desktop max-width 1681px) {
				// max-width: 100%; 
			}

		}

		.image-wrap + p {
			text-transform: uppercase;
			margin-bottom: 0;
			font-size: .9em;
		}

		p, h2 , a {
			padding: 0 2em;
			@include media($medium-screen) {
				padding: 0 ;

			}
		}


		a:first-child {
			display: block; 
		}

		p {
			line-height: 1.5em;
			font-size: 1.05em;
			max-width: 70%;
			margin: 0 auto;

			@include media($medium-screen) {
				max-width: auto;

			}
		}


		a + p {
			font-size: .9em;
			text-transform: uppercase;
			margin-bottom: .9em;
		}

		h2 {
			font-family: $heading-font-family-italic-cuts ;
			font-style: initial;
			margin-top: .25em;
			font-size: 1.6em;
			line-height: 1.5em;
			margin-bottom: .2em;
			
			@include media($medium-screen) {
				font-size: 1.5em;
				line-height: 1.5em ;
			}
			@include media($desktop) {
				font-size: 1.5em;
				line-height: 1.5em ;
			}

			@include media(min-width 1679px max-width 1681px) {
				font-size: 1.5em;
				line-height: 1.5em ;
			}

		}
		
		.button {
			margin-top: .5em;
		}

		img {
			display: inline-block;
			// border-radius: 50%;
			margin: 0 auto ;
			transition: $base-animation ;
			@include media($medium-screen) {
				display: block;
				border-radius: 0; 
			}


		}
		.social-icons{
			display: flex;
			width: 100%;
			justify-content: center;
		}
		.social-icons > a , 
		object {
			width: 100%;
			max-width: 40px;
			max-height: 40px;
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0;
		}

		.social-icons > a:hover {
			transform: scale(1.1);
		}

		.next-section {
			color: #000;
		}

	}

	.swiper-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center; 
		@include media($medium-screen) {
	    align-items: center;
	    padding-top: 1rem;
		}
		@include media($desktop) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center; 
		}
	}

	.section-heading {
		@include media($medium-screen) {
			// margin-bottom: 0;
		}
	}

	.swiper-wrapper {
		@include media($medium-screen) {
			display: flex;
			height: unset;
			flex-direction: row;
			justify-content: space-between;
			max-height: 85vh;
			margin-top: 0;
		}
	}

	.slide {
		@include media($medium-screen) {
			// // max-width: 100%; 
			//height: unset;
			width: 30%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: baseline;
			// padding: .5em;
			// @include span-columns(4);
			// max-width: 30%;
			// margin-right: 3%; 
		}
	}

} 
.swiper-button-next, 
.swiper-button-prev {
	// top: 48% ;
	// margin-top: -110px;
	position: absolute;
	background: url('images/traingle-black.svg') no-repeat center center ;
	top: 280px;
	padding: 1em; 
	.circles & {
			top: calc(50% - 40px);
	}

	.desktop-circles.circles & {
		top: 280px;	
	}
	.squares & {
		top: 260px;	
	}

	&.swiper-button-prev {
		transform: rotateZ(180deg);
		left: 1em;
	}
	&.swiper-button-next {
		right: 1em;
	}
	@include media($medium-screen) {
		display: none;
	}
}

.slider.circles .slide img {
	border-radius: 50%; 
	// padding: 0 2em ;
	max-width: 65vw;
	max-width: 220px;
	@include media($medium-screen) {
		max-width: 100%; 
		padding: 0 ;
		border-radius: 0;
	}

	@include media(1679px) {
		max-width: 100%; 
		padding: 0 ;
		border-radius: 0;
	}

}


section.slider .swiper-slide.slide.pulled img {
	max-width: 240px;
	@include media($desktop) {
		max-width: 240px;
	}
}

.slider.desktop-circles  .image-wrap img {
	border-radius: 50%; 	
	@include media($medium-screen) {
		border-radius: 50% ;
	}
}

.slider  .swiper-slide:not(.pulled) .image-wrap img {
	max-width: 75%;
}


.slider.squares .image-wrap {
	// padding: 0 3.5em 0;
	@include media($medium-screen) {
		// padding: 1em 3.5em 0;
	}
}

.slider.squares img {
	max-width: 220px;
	margin: 0 auto ;



}

.slider .button-container {
	@include clearfix();
	bottom: 1em;
	position: relative;
	a {
		// display: block;
		position: static;
	}

	@include media($medium-screen) {
		bottom: .5vw;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto ; 
		width: 100%;
		a {
			// position: absolute;
		}
	}
	.swiper-wrapper {
		@include clearfix();
		// height: auto;
	}
}

section.slider > a.button {
	@include media($desktop) {
		margin-bottom: 6em;
		margin-bottom: .5em;
		left: 0;
		right: 0;
		position: absolute;
		bottom: 6.5em;
		max-width: 260px;
		margin: 0 auto;
	}
}

section.slider  a.next-section {
	// bottom: .5em;
	// padding-bottom: 1.25em;
	@include media($medium-screen) {
		// padding-bottom: 1.25em;
		// margin-top: 3em;
		// padding-top: 3em; 
	}
}

section.slider .section-heading {
	font-size: 1.75em;
	@include media($medium-screen) {
		font-size: 3em;
		line-height: 1.75em;
	}
}

section.slider .slide.pulled {
	@include media($medium-screen) {
		position: absolute;
		right: 0;
		top: -55%;
		left: auto; 

	}	
	@include media($hires) {
		top: -50%;
		right: 1em;
	}

	@include media(1580px) {
		position: relative;
		transform: translateX(-10%) translateY(-250px) ;
		top: 0;
		right: 10px;
		img {
			margin:0;
			max-width: 310px;
		}
	}
	
}
 

#our-story.circles img {
	border-radius: 0;
}


.bg-slider {

	.swiper-button-next, .swiper-button-prev {
		display: none;
	}

	@include media($medium-screen) {

		.swiper-button-next, .swiper-button-prev {
		
			display: block;
			top: 50%;
			margin: 0;
		}

		.swiper-button-next {
			right: 10%;
		}

		.swiper-button-prev {
			left: 10%;
			transform: rotateX(0);
		}

	}

}