.instruction {
  /*font-size:1.2em;*/
  padding: 0px 10px;
}

.centered {
  text-align: center;
}

.centered.flex-centered {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mobile {
  display:none !important;
}

.center-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left:50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.checking.instruction {
      /* width: 100%; */
      /*height: 220px;*/
      position: absolute;
      z-index: 99;
      background-color: RGBA(0, 0, 0, 0.5);
      color:white;
      display: table;
      /*min-width: 500px;*/
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      border:solid 1px RGBA(0,0,0,1);
      top:50%;
      padding:40px 80px;
}

.checking.instruction p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}


@media screen and (max-width:675px){

  .mobile {
    display:block !important;
  }

  .desktop {
    display:none !important;
  }

  .checking.instruction {
    position:absolute;
    height:100%;
    top:0px;
    width:100%;
    display:block !important;
    padding:0px;
    left:0px;
    transform:none;
  }
  .checking.instruction p {
    position:fixed !important;
    top:50%;
    width:100%;
  }
  .no-availability.instruction {
    /*position:absolute;*/
    padding:0px;
  }

  .bottom-content {
    margin-bottom:100px;

  }a.reselect-dates {
    position: fixed;
    bottom: 0px;
    z-index: 101;
  }

}

#date-selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /*margin: 4px 0px 20px 0px;*/
  min-height: 40px;
  float:left;
  height:100%;
}

#date-selector label {
  padding: 0px 10px;
  margin-bottom:1em;
 text-transform:uppercase;
}

#date-selector div {
  padding-right:10px;
}

.banner-button button {
  margin: 1.5em auto;
  padding: 1.2em 1.5em;
  width: 46%;
  border: 1px solid #1A1919;
  color: #383534;
  background-color: #fff;
  font-weight: 400;
  font-size: 1.1em;
}

@media screen and (max-width:675px) {
  .banner-button button {
    width:92%;
  }
}

.banner-button button:hover {
  color: #fff;
  background-color: #383534;
}

.banner-button{
  width: 100%;
  padding-top: 1em;
  height:100%;
  text-align:center;
}


.modal-booking .current-step a.button.availability-check {

  font-family: "Romain Text", serif;
  letter-spacing: 2px;
  background-color: #1A1919;
  padding: 4px 7px;
  text-align: center;
  color: #eee;
  margin-bottom: 0;
  border: 1px solid #1A1919;
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: block;
  z-index:100;
}

.modal-booking .current-step .room-row .rates a.button, .modal-booking .current-step a.button:hover div, .modal-booking .current-step a.button:focus div, .button-container.desktop a.button:hover div, .button-container.desktop a.button:focus div {
    border: 1px solid #000;
    color: #000;
}

.button-container.desktop a.button:hover, .button-container.desktop a.button:focus, .room-row .rates a.button:hover, .room-row .rates a.button:focus {
  background-color:#FCFAED;
  color:#000;
}

.modal-booking .current-step a.button,.button-container.desktop a.button {
  padding: 4px 7px !important;
}

.modal-booking .current-step a.button.continue.error,
.modal-booking .current-step a.button.continue.error:hover,
.modal-booking .current-step a.button.continue.error:focus {
  background-color: #e36c6c;
  color:#000;
}

.modal-booking .current-step a.button.continue.error div {
  border:solid 1px #000;
}


.modal-booking .current-step a.button div, .button-container.desktop a.button div {
  border: solid 1px #fff;
  padding: 5px 1em;
  min-height:40px;
}

.modal-booking .current-step a.button div.loading:after, .button-container.desktop a.button div.loading:after, .wrap > div.booking-toggle> div.loading:after {
  background: url(images/loader.svg) no-repeat center center;
  content: '';
  position: absolute;
  display: inline-block;
  transform: translateX(5px) translateY(-1px) scale(0.85);
  height: 2rem;
  width: 2rem;
}

.modal-booking .current-step a.button:hover div.loading:after, 
.modal-booking .current-step a.button:focus div.loading:after, 
.button-container.desktop a.button:hover div.loading:after, 
.button-container.desktop a.button:focus div.loading:after,
.wrap > div.booking-toggle:hover > div.loading:after,
.wrap > div.booking-:focus > div.loading:after {
  background: url(images/loader-dark.svg) no-repeat center center;

}


@media screen and (min-width:675px)  {
  .modal-booking .current-step a.button.availability-check {
      max-width: 360px;
      margin-bottom: 5em;
      padding: .5em;
      position: relative;
  }
}



.table {
  width:100%;
  display:table;
  height:110px;
}

input.date-picker, input.booking-form, #wbe_lengthOfStay, #room-count {
  max-height: 60px;
  padding-left: 10px;
  min-height: 40px;
  max-width:170px;
}

td.range-bookend a, .refiner-row a.available {
  border-color:green !important;
}
td.available a, .refiner-row a.available {
border-color:green !important;
background-color:RGBA(0,255,0,0.1) !important;
color:#000 !important;
}
td.available.low-availability a,.refiner-row a.low-availability{
border-color:orange !important;
background-color:RGBA(235,151,15,0.1) !important;
color:#000 !important;
}
td.not-available a,.refiner-row a.not-available {
border-color:red !important;
background-color:RGBA(255,0,0,0.1) !important;
color:#000 !important;
}

td.availability-checked.depart a {
border: 1px solid #c5c5c5 !important;
   background: white !important;
  font-weight: normal !important;
  color: #000 !important;
}
div.comiseo-daterangepicker-buttonpanel {
display:none !important;
}
div.availability-legend, div.room-type-refiner {
text-align:center;
font-size:12px;
font-family:AvenirNextLTPro-Regular, sans-serif;
}

div.room-type-refiner {
  margin:0 auto;
}

div.room-type-refiner p {
  text-transform: uppercase;
  font-weight:bold;
}

.modal-booking .current-step .room-type-refiner .refiner-row .room-type-selector {
  border:solid 1px #ccc;
  cursor:initial;
  font-size:1em;
  min-width:240px;
}

/*div.room-type-refiner a:hover {
cursor:pointer;
}*/

@media screen and (min-width: 1279px) {
div.comiseo-daterangepicker { 
  left:30px !important;
  bottom: 0px !important;
}
}

div.results-legend,div.refiner-row {
  display:flex;
  flex-direction:row;
  align-content:center;
  justify-content:center;
  margin-bottom:0px;
}
div.availability-legend-mobile {
  display:none;
}

.legend-row, .legend-row span {
  display:inline-block;
}

@media screen and (max-width:675px){

  div.refiner-row .room-type-selector {
    width:90%;
  }
  div.results-legend, div.refiner-row {
    display:flex;
    flex-direction:column;
    align-content:center;
    justify-content:center;
  }

  .legend-row {
    text-align:left;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
  }

  .comiseo-daterangepicker .dp-highlight.depart a:after, .comiseo-daterangepicker .dp-highlight.arrive a:after, .calendar .ui-datepicker-group .dp-highlight.depart a:after, .calendar .ui-datepicker-group .dp-highlight.arrive a:after {
    font-size:6px !important;
  }

  div.availability-legend-mobile {
    display:inline-block;
  }
}