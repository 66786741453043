section.complete {
	background: url('../assets/content/background/BG-Thank-you.jpg') no-repeat -414px -500px; 
	// background-size: 100vh ;
	padding-top: 3vw; 
	position: relative;
	text-align: center;

	clear: both;

	@include media($medium-screen) {
		background: url('../assets/content/background/BG-Thank-you.jpg') no-repeat center -500px; 
		// background-size: initial; 
	}

	h2 {
		font-size: 3em;
		color: #fff;
		max-width: 480px;
		margin: 0 auto; 
		padding: 1em 0 1em;
		text-shadow: $text-shadow ;
		font-weight: normal;
		// font-family: $heading-font-family;
		@include media($medium-screen) {
			padding: 4.5em 0 4em;
		}
	}

	button {
		color: #fff;
		border: 1px solid #fff;
		margin-bottom: 6em;
		background-color: rgba(0,0,0,0.3);
		@include media($medium-screen) {
			margin-bottom: 15em;
		}
		&:hover {
			background-color: rgba(0,0,0,0.6);
		}
	}

	.text {
		background-color: #fff;
		margin: 0 auto;
		width: 100%;
		padding-bottom: 5vw;
	}

	.container {
		max-width: 950px;
		padding-bottom: 4em;
		margin-top: 2em;
		  p:first-of-type::first-letter {
			font-size: 2em;
		}

		p {
			text-align: left;
			@include media($medium-screen) {
				max-width: 65%;
			}
		}
	}

	picture {
		background-color: #fff;

		@include media($medium-screen) {
			background-color: transparent;
			bottom: 5vw ;
			position: absolute;
			// right: 0vw;
		}
	}

}