.image-gallery {
	.container {
		// padding: 0;
		@include media('900px') {
			padding: 0;
		}
	}
	.grid-wrap {
		&:before, &:after {
			display: table;
			content: "";
		}

		.image-item {
			position: relative;
			min-height: 1px;
			padding: 3px;
			line-height: 0;
			margin: 0;

			@include media('500px') {
				float: left;
				width: 50%;
			}
			@include media('992px') {
				width: 25%;
			}

			.item-wrap {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				
				.hoverlay {
					height: 250px;
					background-color: rgba(0,0,0,0.5);
					transition: all 300ms ease;
					opacity: 0;
					display: flex;
					align-items: center;

					.action-view {
						color: #fff;
						display: block;
						margin: auto;
						text-transform: uppercase;
						text-decoration: none;
						font-family: "Romain Headline", serif;
						letter-spacing: 2px;
						text-align: center;
						border : 1px solid #fff;
						padding: 23px 20px 20px 20px;
						width: 150px;
						// transition: all 300ms ease;
						// &:hover {
						// 	background-color: #fff;
						// 	color: #000;
						// }
					}
				}

				&:hover {
					.hoverlay {
						opacity: 1;
					}
				}
			}
		}
	}
}

// START Library Overrides
body.mfp-zoom-out-cur {
	overflow: hidden;
}
.mfp-bg {
	z-index: 6001;
}
.mfp-wrap {
	z-index: 6002;
	cursor: default;
}
// .mfp-figure {
// 	display: flex;
//     align-items: center;
// }
.mfp-counter {
	top: 15px !important;
}
.mfp-title {
	bottom: 0;
    position: absolute;
    width: 100%;
    padding: 10px;
    @include media('768px') {
		padding: 40px;
    }
	.mfc-image-title {
    	font-size: 25px;
    	@include media('768px') {
    		margin: 0 0 .5em;
    	}
    }
}
.mfp-arrow {
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-size: contain !important;
	height: 50px !important;
	top: 54% !important;
	&:hover, &:focus {
		background-color: transparent;
		outline: none;
	}
	&:before, &:after {
		display: none !important;
	}
	&.mfp-arrow-left {
		background-image: url('images/icon-left-chevron-circle.svg') !important;
	}
	&.mfp-arrow-right {
		background-image: url('images/icon-right-chevron-circle.svg') !important;
	}
}
// END Library Overrides