.focus-slider {
	max-width: 100vw; 
	overflow-x: hidden; 
	.swiper-container {
		width: 100%;
		overflow: visible;
		margin: 2.25em auto 1em;
		@include media($medium-screen) {
			margin: 8.25em 0 5em;
		}
	}

	.swiper-wrapper {
		padding: 0;

	}

	.swiper-slide {
		position: relative;
		z-index: 300;
		transition: .5s ease all;
		margin: 0;
		// transform: scale(1) , translatex(7.5%);
		// max-width: 85%;
		.slide-image {
			min-height: 75vw; 
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			margin: 0;
			@include media($medium-screen) {
				min-height: 25vw;
			}

			@include media($desktop) {
				min-width: 40% ;
			}

		}
	}

	@include media(max-width $large-screen) {
		.swiper-slide.swiper-slide-active {
			max-width: 100%;
			// margin-left: 10%; 
			z-index: 400;
			// transform: scale(1.15);
			overflow: visible;

			&:before {
				transition: .5s ease all;
			}
		}
	}
	@include media($large-screen) {
		.swiper-slide.swiper-slide-next {
			z-index: 400;
			
			transform: scale(1.15);
			overflow: visible;

			&:before {
				transition: .5s ease all;
			}
		}
	}


	@include media(max-width $large-screen) {
		.swiper-slide:not(.swiper-slide-next) .slide-image {
			position: relative;
			&:before {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba(0,0,0,0.25);
			}

		}

	}

	@include media($large-screen) {

	.swiper-slide .slide-image  {
		position: relative;
		// min-width: $max-width;
		&:before {
			transition: .2s ease background-color;
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 125;
			background-color: rgba(0,0,0,0.0);
		}
	}

	.swiper-slide:not(.swiper-slide-next) .slide-image { // 'inactive' Slide
		position: relative;
		
		// opacity: .4;
		&:before {
			background-color: rgba(0,0,0,0.55);
		}

	}

	}	

	.description {
		opacity: 0;
		text-align: center;
		transition: .5s ease all; 
		padding: 1em ;
		font-size: 1.05em;
		line-height: 1.5em;
		@include media(max-width $medium-screen) {
			font-size: 1.1em;
			line-height: 1.5em;
			padding: 1em 4.5em 0;
			margin-bottom: 0;

		}
		@include media($desktop) {
			padding: 2em 2em 0 ;
			margin-bottom: .5em;
		}
		.swiper-slide-active & {
			opacity: 1;
		}
	}
	
	@include media(max-width $medium-screen) {
		.swiper-slide-active .description {
			opacity: 1;
		}
	}

	@include media($medium-screen) {
		.swiper-slide-next .description {
			opacity: 1;
		}
	}



	.buttons {
		display: block;
		max-width: calc( #{$max-width} * .90 );
		margin: 0 auto ;
		clear: both;
		float: none;
		position: relative;
		
		@include media(max-width $medium-screen) {
			top: -120px;
		}
		@include media($large-screen) {
			top: -10px;
		}
		&:before, &:after {
			content :'';
			clear: both;
			width: 100%;
			display: table;
		}
	}


	.swiper-button-prev, .swiper-button-next {
		display: block;
		top: auto;
		position: relative;
		z-index: 250;
		background-image: url('images/icon-chevron-circle.svg');
		background-size: cover ;
		margin-bottom: 0;
		// bottom: 45%; 
		width: 30px;
		height: 30px;

		@include media($large-screen) {
			// bottom: -7em ;
			width: 50px;
			height: 50px;
		}


		&.swiper-button-prev {
			transform: rotateZ(0deg);
			float: left;
			left: 20px;
			@include media($large-screen) {
				left: 0;
			}
		}
		&.swiper-button-next {
			transform: rotateZ(180deg);
			float: right;
			right: 20px;
			@include media($large-screen) {
				right: 0;
			}
		}
	}


	.swiper-pagination {
		bottom: -4em;
		@include media($large-screen) {
			// bottom: -12em;
		}
		> .swiper-pagination-bullet {
			border-radius: 0;
			width: 10px;
			height: 10px;
			transform: rotateZ(45deg);
			background: #fff;
			border: 1px solid #000;
			&.swiper-pagination-bullet-active {
				background: #000;
			}
		}
	}

}