section.white {
	p {
		color: #fff;
	}

	h1 {
		&.section-heading {
			color: #fff;
		}
	}


	h2 {
		color: #fff;
	}

	a.button {
		&.slim {
			color: #fff;
			border-bottom: 1px solid #fff;

			&:after {
				color: #fff;
			}


			&:hover {

				border-bottom: 1px solid $medium-gray;

				color: $medium-gray;

				&:after {
					color: $medium-gray;
				}
			}
		}
	}

	.signposts {
		a {
			color: #fff;
			@include media($large-screen) {
				background: url('images/border-signpost-left.svg');

				&:nth-child(2) {
					background-image: url('images/border-signpost-right.svg');
				}
			}

			&:hover {
				color: #fff;

				@include media($large-screen) {

					background: url('images/border-signpost-left-hover.svg');
					&:nth-child(2) {
						background-image: url('images/border-signpost-right-hover.svg');
					}
				}
			}
		}
	}
}


section.black {
	p {
		color: $dark-gray;
	}

	.signposts {
		a {
			color: $dark-gray;
			@include media($large-screen) {
				background: url('images/border-signpost-left-dark.svg');

				&:nth-child(2) {
					background-image: url('images/border-signpost-right-dark.svg');
				}
			}

			&:hover {
				color: #fff;

				@include media($large-screen) {

					background: url('images/border-signpost-left-dark-hover.svg');
					&:nth-child(2) {
						background-image: url('images/border-signpost-right-dark-hover.svg');
					}
				}
			}
		}
	}



	.button	{

		border-color: $dark-gray;
		color: $dark-gray;

		&:hover {
			background-color: $btn-gray;
			border-color: $dark-gray;
			color: #fff;

			&:before {
				transform: scaleX(0);
			}
		}

		&.slim {
			border-bottom: 1px solid #000;

			&:after {
				color: #000;
			}

			&:hover {
				border-bottom: 1px solid $hightlight-gray;

				color: $hightlight-gray;

				&:after {
					color: $hightlight-gray;
				}
			}
		}

	}

	h2 {
		color: $dark-gray;
	}
}