$_button-background-color: $action-color;
$_button-background-color-hover: shade($action-color, 20%);
 
#{$all-buttons} {
	appearance: none;
	background-color: $_button-background-color;
	border: 0;
	border-radius: $base-border-radius;
	color: contrast-switch($_button-background-color);
	cursor: pointer;
	display: inline-block;
	font-family: $text-font-family;
	font-size: 1.25em ;
	-webkit-font-smoothing: antialiased;
	/*font-weight: 600;*/
	line-height: 1;
	padding: $small-spacing $base-spacing;
	text-align: center;
	text-decoration: none;
	transition: background-color $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	text-transform: uppercase;

	&:hover {
		// transform: scale(1.1);
		background-color: $_button-background-color-hover;
		color: contrast-switch($_button-background-color-hover);
	}

	&:focus {
		outline: $focus-outline;
		outline-offset: $focus-outline-offset;
	}

	&:focus, &:active {
		transform: translateY(-1px);
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;

		&:hover {
			background-color: $_button-background-color;
			box-shadow: $glow; 
		}
	}
}

a.button , 
span.button {
		// text-shadow: $text-shadow;
		letter-spacing: 2px;
		// font-size: 1.25em;
		border: 1px solid currentColor;
	
		text-transform: uppercase;
		display: inline-block;
		text-decoration: none ;
		color: inherit; 
		// font-family: $base-font-family; 
		font-family: $text-font-family;
		@extend .hvr-sweep-to-right;
		font-size: .875em;
		padding: .5em 1.65em;
		@include media($medium-screen) {
			font-size: 1em;
			padding: .5em 2.5em;
		}

		&:hover {
			color: #000;
			box-shadow: $glow; 
			// background: #fff; 
			// transform: scale(1.1);
		}
}

.wrap > div.booking-toggle {
	animation: fadein 2s normal forwards ease-in ;
	background: $btn-gray;
	font-family: $heading-font-family;
	text-transform: uppercase;
	//transition: .1s ease opacity !important;
	padding: 4px 7px ;
	margin-bottom: 0;
	position: fixed; 
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 5355;
	width: 100%; 
	max-width: 100vw;
	color: #fff; 
	display: block;
	//font-size: .9em;
	font-size:15px;
	@extend .hvr-sweep-to-right; 
	@include media($large-screen) {
		position: fixed;
		font-size: 1em;
		top: auto;
		right: auto ;
		bottom: 1.25em;
		left: 1.25em;
		width: auto;
	}
	> div.border {
		letter-spacing: .15em;
		text-align: center; 
		border: 1px solid #fff;
		margin: 3px ;
		padding-top: 7px;
		//padding:  7px 35px;  
		//transition: .2s ease all;
		width: 100% ;
		max-width: calc(100% - 6px) ;
		@include media($medium-screen) {
			max-width: 100%;
			margin: 3px auto ;
			display: inline-block;
		}
		min-height: 40px ; 
		display: block;
		.open & {
			width: 100%;
		}
		&:focus,
		&:active,
		&:hover {
			color: #000;
			background-color: $offwhite !important ; 
		}
		
		span {
			position: relative;
			top: 4px;
			
			@include media($medium-screen) {
				top: 0;
			}
		}
	}

	&:before,
	&:hover:before {
		background-color: $offwhite !important ; 

	}

	&:hover {
		background-color: $offwhite !important ; 
		div.border {
			border: 1px solid #000;
			color: #000;
		}
	}

	span {
		display: inline-block;
		@include media($medium-screen) {
			margin-top: .25em; 
		}
	}

	&.form-open {
		left: -100%; 
	}

	&:focus,
	&:active,
	&:hover {
		cursor: pointer; 
		box-shadow: $glow; 
	}

}

.full-menu button {
	width: 100% ;
 	max-width: 100%;
 	text-decoration: none;
 	@include media($large-screen) {
 		max-width: 85%;
 	}
	border-radius: 0; 
	background: #fff;
	padding: 4px ;
	display: block;
	margin-top: .5em;
	span {
		font-size: .85em ;
		border: 1px solid #000;
		color: #000 ;
		width: 100% ;
		padding: .75em 1em;
		display: block; 
		transition: $base-animation ;
	}
	@extend .hvr-sweep-to-right; 
	&:hover {
		border: 1px solid $offwhite ;
	}
	&:hover:before {
		background: $medium-gray ;
		// box-shadow: $glow; 
	}
	&:hover span{
		border: 1px solid $offwhite; 
		// transform: scale(1.1);
		// background: $offwhite;
		color: #fff ;
	}
}


div.button-container {
	padding: 0 0 1px 0;
	margin: 0 auto; 
	position: absolute;
	left: 0;
	right: 0;
	bottom: 3.25em; 
	max-width: 375px;
	z-index: 200; 
	width: 100%;
	@include media($large-screen) {
		bottom: 3em; 
	}
}


section.button-container > a {
	&:before {
		background-color: $offwhite; 
	}
	&:hover {
		color: #000;
		span {
			border-color: #000;
		}
	}
}


section.full-screen:not(#home) .button-container {
	// bottom: 0;


}

.full-screen {
	a.button, button {
		background-color: rgba(0,0,0,0.15);
		&:hover {border-color: #fff   ; }
		font-size: 1em;
		@include media($large-screen) {
			font-size: .875em;
		}
	}

}



.bg-slider , #home {
	div.button-container {
		bottom: 5.25em;
		@include media($large-screen) {
			bottom: 3em;
		}
	}


}

// .home .button-container {
// 	bottom: 1em;
// }

 

section.button-container {
	margin: 0 auto 1.5em ;
	max-width: 85vw;
	text-align: center;
	a {
		margin: 0 auto;
		display: block;
		width: 100%;
		max-width: 500px;
		background: $btn-gray;
		color: #fff;
		padding: .5em;
	}

	a span {
		margin: 0 auto;
		width: 100%;
		display: block;
		border: 1px solid #fff;
		padding: .51em .6em;
	}
}


a.next-section {
	animation: fadein 2s normal forwards ease-in ;
	// max-width: 375px;
	margin: 0 auto 0 ;
	// position: absolute;
	width: 100%; 
	padding: 1.5em 0  ;
	
	display: none;

	@include media($medium-screen) {
		display: block;
	}

	img {
		z-index: 20;
		display: block;
		margin: 0 auto ;
		max-height: 100%;
		max-width: 45px; 
		@include media($large-screen) {
			max-width: 65px; 
		}
		&:hover {
			transform: scale(1.05);
			cursor: pointer; 
		}
	}

	position: absolute;
	bottom: -2.5em;

	@include media(min-width  375px) {
		bottom: 1.5em;
	}

	@include media(min-width  425px) {
		bottom:  -2.5em;
	}

	@include media($desktop + 2px) {
		position: relative;
		bottom: 0;
		left: auto;
		right: auto;
		width: 100%;
		max-width: 100%
	}

	.home & {
		// bottom: 0 ;
		a {
			padding: 0;
		}
	}



}

section#home .next-section img {
	margin-bottom: -3em;
	@include media($medium-screen) {
		// bottom: .5em;
		margin-bottom: -3em;
		padding-bottom: 2em;
	}

}


section a.button.slim {
	// text-decoration: underline;
	transition: $base-animation ; 
	border-top: 0 solid #fff;
	border-right: 0 solid #fff;
	border-left: 0 solid #fff;
	outline: 0;
	padding: .5em;
	font-family: $heading-font-family-italic-cuts ;
	font-style: initial;
	text-transform: none; 
	line-height: 2em;
	letter-spacing: 0px;
	font-size: 1.1em;
	@include media($large-screen) {
		padding: 0 3px 1px; 
		border-bottom: 1px solid #000;
		font-size: 1.1em;
		line-height: 1.5em;
	}
	&:before {
		display: none;
	}
	&:after {
		content: '>';
		display: inline-block;
		margin-left: .5em; 
		transition: $base-animation ;
	}
	&:focus,
	&:active,
	&:hover {
		outline: 0;
		border-bottom: 1px solid transparent;
		box-shadow: 0 0 0 #fff ;
		background-color: transparent;

		&:before {
			display: none;
		}
	}
	&:hover:after {
		// margin-left: 1em;

	}
}

.signposts {
	max-width: 100vw; 
	position: static;
	clear: both ;
	font-family: $heading-font-family;
	@include media($large-screen) {
		bottom: -3em;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		padding-top: 2.25em;
	}
	@include media($desktop) {
		bottom: -6.5vw;
	}
}
.signposts > a {
	font-size: .9em;
	letter-spacing: 2px;
	display: inline-block;
	vertical-align: top;
	color: #fff;
	padding: .7em 1em .25em 1em ;
	min-width: 280px;
	max-width: 320px;
	height: 40px;
	text-transform: uppercase;
	margin-bottom: 2em;
	border: 1px solid #fff;
	text-decoration: none;
	transition: $base-animation ;
	@include media($large-screen) {
		font-size: .95em;
		border: none;
		background: url('images/border-signpost-left.svg');
		margin-bottom: 0 ;
		background-size: 100%  100% ; 
		background-position: top ;
		background-repeat: no-repeat;
		&:nth-child(1) {
			text-align: right;
			padding-right: 2.5em;
			// margin-right: 2.25em;
		}
		&:nth-child(2) {
			// border-radius: 25px 0  25px;
			padding-left: 2.5em;
			margin-left: 4em;
			text-align: left;
			// padding-left: 1.5em;
			background-image: url('images/border-signpost-right.svg');
		}
	}

	&:hover {
		@include media(max-width $large-screen) {
			background: #fff;
			color: #000;
		}
		@include media($large-screen) {
			text-shadow: $text-shadow;
			transform: scale(1.05);
			color: #000 ; 
			background: url('images/border-signpost-left-hover.svg');
			&:nth-child(2) {
				background-image: url('images/border-signpost-right-hover.svg');
			}
		}
	}
}

.hvr-sweep-to-top {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    transition: $base-animation ;
}

.hvr-sweep-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.85);
    color: #000;
    transform: scaleY(0);
    transform-origin: 50% 100%;
	transition: $base-animation ;
}

.hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

// To Right

.hvr-sweep-to-right {

	display: inline-block;
	vertical-align: middle;
	//-webkit-transform: perspective(1px) translateZ(0);
	//transform: perspective(1px) translateZ(0);
	// box-shadow: 0 0 1px transparent;
	//transition: .2s ease all; 
	outline: 0;
	box-shadow: 0 0 0 ;
}

.hvr-sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //transform: scaleX(0);
    //transform-origin: 0 50%;
    //transition-property: transform;
    //transition-duration: 0;
    background: rgba(255,255,255,0.85);
    color: #000;
    box-shadow: 0 0 0 ;
}


.hvr-sweep-to-right:hover  {
    color: #000;
    box-shadow: 0 0 0 ;
}

.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
    //-webkit-transform: scaleX(1);
    //transform: scaleX(1);
    box-shadow: 0 0 0 ;
}

.hvr-sweep-to-right:before {
	box-shadow: 0 0 0 ;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #000;
    background: rgba(255,255,255,0.85);
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0; 
    transition-timing-function: ease-out;
}



// Close button


@mixin close-button() {

		// display: none;
		text-align: right;
		z-index: 50;
		> a {
			// position: absolute;
			// right: .5em;
			// top: -.5em;
			width: 30px;
			height: 30px; 
			display: inline-block;
			float: right; 

			&:after, &:before {
				transition: $base-animation ; 
				background-color: #fff; 
				display: block;
				content: '';
			    position: absolute;
			    width: 30px;
			    height: 3px;
			    transition: .5s ease all; 
			    border-radius: 4px;
			    top: 1.25em; 
			    @include media($medium-screen) {
			    	top: 1.5em; 
			    }

			}

			&:before {
				transform: translate3d(0,0,0)   rotate(45deg);
			}
			&:after {
				transform: translate3d(0,0,0)   rotate(-45deg);
			}
		}


	> a:hover  {
		// &:before, &:after {transform: scale(1.05);}
	}

}


form button {
	display: block;
	background: #fff;
	border: 1px solid #000;
	min-width: 250px;
	margin: 0 auto; 
	@include media($medium-screen) {
		margin: 0 ;
	}
	&:hover {
		background: #000;
		color: #fff;
	}
}


.page-discovery .button-container .btn-white,

section.button-container .btn-white {
	background: #fff;
	border: 1px solid #000;
	text-decoration: none;
	padding: 4px;
	text-transform: none;
	font-family: $heading-font-family-italic; 
	font-weight: bold;
	font-size: 1.2em;
	> span {
		transition: .2s ease all;
		color: #000;
		// background-color: #fff;
		border: 1px solid #000;
		display: inline-block;
		margin: 1px  auto;

	}	
	@extend .hvr-sweep-to-right;
		&:before {
			background-color: #000;
			color: #fff; 
		}

	&:hover span {
		color: #fff;
		border-color: #fff;
	}	
}


.page-discovery .button-container .btn-white {
	background-color: transparent;
	span {
		margin:  0 auto;
		width: 100%;
	}
}

.hvr-sweep-to-right {
	// placeholder, awaiting git merge
}


#rituals-happenings, #connect-share {
	.button-container {
		.button  {
			@extend .hvr-sweep-to-right ; 
			margin-bottom: 0;
			@include media($desktop) {
				margin-bottom: 3em;
			}
			
			&:hover {
				color: #fff; 
			}
			&:hover:before {
				background-color: $medium-gray;
			}

			&:active , &:focus {
				color: #fff;
				&:before {
					background-color: $medium-gray ;
				}
			}
			
		}
	}
}

a.download-button {
	background-color: #000;
	color: #fff;
	float: left;
	margin-top: 2em;
	&:before {
		background-color: #fff;
	}
}


.full-screen a.button ,
.slider .button-container a {
    // position: static;
    // min-height: 40px;
    // padding-left: 2.5em;
    padding-right: 2.5em;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 40px;
    font-size: 13px !important;
}

#local-discovery.full-screen a.button {
	&:focus, &:active {
		color: #fff;
		&:before {
			background-color: $medium-gray !important ; 
		}
	}
}