.discovery h1 {
	text-align: center; 
	@include media($medium-screen) {
		text-align: left ;
	}
}

.discovery {
	background: url('images/bg-story.jpg') no-repeat top center; 
	background-size: cover; 
	min-height: 85vh;
	text-align: center ;
	padding: 6em 1em 3em;
	@include media($medium-screen) {
		padding: 6em 1em;
		text-align: left;
	}
	@include media($desktop) {
		padding: 6em 0 ;
	}

	.container:first-of-type() p {
		margin: 1em 0;

		// max-width: 85vw; 
		@include media($medium-screen) {
	 
			max-width: 50%;
		}		
		@include media($large-screen) {
			padding: 0; 
		}
	}

	.container {
		margin: 0 auto;
		padding:0;
	}

	.button-container {
		position: static;
		@include clearfix();
		text-align: center; 
		max-width: 85vw; 
		margin: 0 auto; 
		@include media($medium-screen) {
			max-width: 500px;
			margin: 2em auto; 
		}
		// border: 1px solid #000;
		// padding: 5px ;
	}

	.button-container > a {
		width: 100%;
		border: 1px solid #000;
		padding: .5em .5em;
		// font-size: .65em;
	}
}

.discovery .grid-5 {

	@include clearfix();

	margin-top: 2em; 

	@include media($medium-screen) {
		margin-top: 6em;
		display: flex;
		align-items: unset; 
	}
	.item {
		display: block; 
		text-decoration: none;
		width: 100%;
		max-width: 85vw;
		margin: 0 auto 3em ;
		@include media($medium-screen) {
			text-align: center; 
			width: 20%;
			margin: 0;
			transition: .2s ease all;
			&:hover {
				transform: scale(1.1);
			}
		}
	}

	.item picture {
		min-height: 200px;
	}

	.item h3 {
		// min-height: 185px;
		font-family: $heading-font-family-italic; 
		font-size: 1.1325em;
		position: relative;
		margin: 0 auto 1.5em;
		text-transform: none;
		@include media($medium-screen) {
			margin: .5em 0 2.5em;
		}

		&:after {
			content: '';
			position: absolute;
			bottom: -10px;
			right: 0;
			left: 0;
			border-bottom: 1px solid #000;
			max-width: 55%;
			margin: 0 auto ;
		}
	}

	.item p {
		font-size: 1.01em;
	}

}