.modal-booking {
	background-color: #fff;
	// position: absolute;
	// top: 60px;
	right: 0;
	left: 0;
	// height: calc(100vh - 60px) ;
	z-index: 15000;
	// overflow: scroll;
	ul {
		margin-left: 0;
		padding-left: 0;
	}

	.booking-header {
		@extend header ; 
		position: static;
		z-index: 50;

		object {
			@include media($medium-screen) {
				max-width: 100% !important ;
				position: absolute;
			}
			left: 0;
			right: 0 ;
			margin: 0 auto;
			height: auto; 
		}

	}

	.amenities ul li { 
		@include media($medium-screen) {
			font-size: 1.1em;
		}
	}

}

body.booking { 
	.modal-target, .overlay , .booking-toggle , footer {
		display: none;
	}

	&.complete footer {
		display: block;
	} 
	header {
		background: $btn-gray;
	}
	&.menu-open {
		header {
			background: $medium-gray;
		}
	}
}

.booking-steps .steps >  li.details {
	// top: 60px;
	border-bottom: 1px solid #fff;
	padding: .2em;


	a {
		padding: 0 0 1em ;
		position: relative;
		color: #fff;
		font-size: .8em;
		font-family: $base-font-family-bold;
		font-weight: normal;
		&:after {
			transition: .5s ease transform;
			background: no-repeat center center ;
			background-image: url('../assets/images/icon-chevron-down-white.svg');
			content: '';
			position: absolute;
			width: 10px;
			height: 10px; 
			top: 6px; 
			margin-left: 1em;
			// right: -5px;
		}
	}

	&.flip a:after {
		transform: rotateZ(180deg);
	}
}

.booking-steps .steps >  li:not(.details) {
	display: none;
	a {
		min-height: 75px;
		@include media($medium-screen) {

		}
	}
	@include media($medium-screen) {
	 	display: block;
	 	
	}
}

.booking-steps {
	background: $btn-gray;
	padding-bottom: .5vw; 
	z-index: 1000;
	position: fixed; 
	width: 100%;
	top: 60px; 
	@include media($medium-screen) {
		// top: 110px; 
	}

	top: 60px;
    position: sticky;
    width: 100%;
    z-index: 100;

	&.open {
		top: 60px;
	    height: 100%;
	    position: fixed;
	    z-index: 1000;
	    position: fixed;
	    width: 100%;
	}

	.steps {
		@include clearfix;
		text-transform: uppercase;
		margin: 0 auto 0;
		padding: 0;
		border-top: 2px solid #fff;
		@include media($medium-screen) {
			border: 1px solid #fff;
			max-width: 99vw;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		
		li {
			position: relative;
			z-index: 500;
			margin: 0;
			text-align: center; 
			margin-bottom: 0;
			list-style-type: none;

			@include media($medium-screen) {
				border-bottom: 15px solid $btn-gray; 
			}

			transition: .5s ease border; 
			&:not(.details) {
				// display: none;
			}

			&:not(:nth-child(2)) {
				&:after {
					@include media($medium-screen) {
						content: '';
					}
					width: 0px;
					height: 31px;
					bottom: calc( 60% - 31px );
					left: 0;

					border-left: 1px solid #fff;
					position: absolute;
				}
			}

			@include media($medium-screen) {
				&.details {
					display: none;
				}
				display: inline-block;
	 			flex-direction: column;
				min-height: 125px;
				width: 20%;
			}
			a {
				display: block;
				width: 100%;
				text-decoration: none;
				color: #c8c8c8;

			    margin-top: 1.25em;
			    border-bottom: 1px solid #fff;
			    @media screen and (max-width: 768px){
					//padding: 2.5em .3em .5em .3em !important;
				}

				@media screen and (max-width: 640px){
					padding: 0 0 1em;
				}
				@include media($medium-screen) {

					border-bottom: 0px solid #fff;
					margin: 0; 
					padding: 2.5em 1.5em .5em 1.5em;
				}

			}

			&.details a , &:last-child a {
				border: 0; 
			}

			&.current-step {
				@include media($medium-screen) {
					border-bottom: 15px solid $offwhite; 
				}
				a {
					color: #fff;
				}

			}
			&:hover {
				@include media($medium-screen) {
					border-bottom: 15px solid $light-gray; 
				}
			}
		}

	}

}


.modal-booking {
		form {
		width: 100%;

		> label {
			margin-bottom: .25em; 
		}

		label input , label textarea {
			font-size: 14px;
			padding: .75em ; 
		}
	}
}

.modal-booking form {

	margin: 0 auto;
	max-width: calc(100% - 1.5em); 
	@include media($small-screen) {
		width: 100%;
	}

	&.summary-form {
		max-width: 100%;
	}
	
	label {
		clear: both ;
		margin-bottom: 0;
		&:after{
			clear: both;
			content: '';
			float: none; 
			display: block; 
		}
	}
	label input, label select {
		float: left; 
		position:relative;
		z-index:2;
		margin-bottom: 1.5em;
		background:none;
		padding: 1.5em 1.25em 1.25em 1.25em; 
		-webkit-appearance: none; 
		box-shadow: 0;
		border: 1px solid #DEDEDE;
		border-radius: 0;
		font-size: 14px;
		//color: #999;
		color:black;
		outline: none;
		transition: .5s ease outline; 
		&:active, &:focus {
		    outline: 5px solid #dedede;
    		border: 1px solid #fff;
		}
	}

	.label-2-3 , .label-2-2 {
		clear: both; 
		margin-bottom: 0;
		&:after{
			clear: both;
			display: block;
			content: '';
		}
		label {
			float: left; 
			width: 100% ;
			clear: none;
		}
		input, select {
			width: 100%;
			margin-bottom: 1em ;
			min-height: 65px;
			background:none;
		}
	}

	.label-2-3 > label , .label-2-3 > select {
		&:first-child {
			max-width: calc(30% - 1.5em) ;
			margin-right: 1.5em;
		}
		&:nth-child(2) {
			max-width: 70%;
		}
	}
	.label-2-2 > label , .label-2-2 > select {
		max-width: 50%;
		&:first-child {
			max-width: calc(50% - 1.5em); 
			margin-right: 1.5em;
		}
	}

	label .country-select {
		width: 100%; 
	}

	.country-select.inside .selected-flag {
	    width: 46px;
	    height: 56px;
	    float: right;
	}

	ul.country-list {
	    width: 100%;
	    max-width: 100%;
		background: white;
		position:relative;
		z-index:3;
	}

	.flag-dropdown {
	    width: 100%;
	}

	.flag-dropdown .country-name {
		position: relative ; 
		font-size: 14px; 
		padding: 0 .5em;
		top: 0; 
	}

	#country_code {
		text-transform: uppercase;
	}

	// #country + .flag-dropdown .country-list.visible {
	// 	display: block !important; 
	// }

}

.booking-steps li {



	&:not(.enabled) {
		a {
			font-size: 1.2em;
			@media screen and (max-width: 768px){
				font-size: .9em;
			}
			@include media($medium-screen) {
				position: absolute;
				top: 10%;
				transition: .5s ease all;
			}
		}
	}

	&.enabled a {
		@media screen and (max-width: 768px){
			font-size: .9em;
		}
		@include media($medium-screen) {
			top: 0%;
		}
	}

	.detail {
		display: block;  
		font-family: $heading-font-family;
		font-size: 1.5em;
		// display: none;
		text-transform: none;
		position: absolute;
		left: 0;
		right: 0;
		@media screen and (max-width: 768px){
			font-size: 1.2em;
		}
		@include media($medium-screen) {
			bottom: -.5em;
		}
		transition: .5s ease all; 
		opacity: 0;

	}

	&.enabled .detail {
		// display: block; 
		@include media($medium-screen) {
			bottom: 1.15em;
		}
		opacity: 1;
	    max-height: 38px;
	}
	

}

 

.modal-booking .tab-content {


	&.current-step {
		display: inherit;
		min-height: 70vh;
	}
}

.modal-booking .current-step {
	// height: calc( 100vh - 60px ) ;

	width: 100%;
	margin: 0 auto 0 ;
	padding-bottom: 30px;

	@include media($medium-screen) {
		padding-bottom: 0;
	}

	// overflow-y: scroll; 

	.button-container {
		text-align: center;
		a.button {
			background: $medium-gray;
			color: #fff;
			padding: .5em 5em;
		}
	}

	> h2 , > h3 {
		margin-bottom: 1.5em;
	}

	> h2 {
		@include media($medium-screen) {
			margin-top: 2.5em; 
			font-size: 30px;
		}
		margin-top: 1.5em; 
		font-size: 22px;
		font-family: $heading-font-family-italic ;
		text-align: center ;
		// margin-bottom: 2em;

	}

	> h3 {
		font-family: $heading-font-family-italic;
		text-align: center;
		font-size: 20px;
	}
 
	.field-group {
		padding: 0 1.5em 1.5em ;
		margin: 0 auto 1.5em;
		position: relative;
		@include media($large-screen) {

			width: calc(100% - 1.5em);
			max-width: 910px;
			display: flex; 
			padding: 0;
			align-items: center;
		}

		&.date-info {
			margin-bottom: 3em;
		}

		h3 {
			margin: 0 0 .125em;
		}

		label {

			@include media($medium-screen) {
				display: flex; 
				// display: inline-block;
			}
			
			span {
				font-size: 1em;
				letter-spacing: .1em;
				text-transform: uppercase;
				margin-right: 1em;
				float: left;
				font-weight: normal;
				font-family: $base-font-family-bold;
				margin-top: 1.5em;
				@include media($medium-screen) {
					font-size: 1.1em;
					float: none;
					margin-top: inherit;
				}
			}

			input {
				font-size: 1.3em;
				width: 70%;
				@include media($medium-screen) {
					width: auto;
					font-size: 1.8em;
				}
				border: 1px solid #fff;
				outline : 0 ;
				border-bottom: 1px solid $medium-gray ;
				margin-bottom: 0;
				box-shadow: 0 0 0 ;
				/*text-align: center;*/
				margin-right: 1em; 
			}
		}

	}


	.group-heading {

		width: 100%; 
		text-align: center;
		margin-bottom: .5em;

		> h3 {
			text-align: center; 
			display: inline-block;
			font-family: $heading-font-family-italic;
			text-transform: none; 
			font-size: 1.7em;
			color: $medium-gray;
		}



		.remove-group {

				// content: '&times'; 
				position: absolute;
				z-index: 500;
				display: inline-block;
				left: 0;

				width: 50px;
				height: 50px;
				line-height: 50px;
				font-size: 50px;
				margin:0;
				text-decoration: none;
				@include media($medium-screen) {
					top: 0px;
				}
				top: -15px;
			}
	}

	
	.field-group.field-group-text {
		label {
			@include clearfix();
			display: flex;
			margin-bottom: .5em;

			span , input {
				display: inline-block;
			}
			span {
				font-size: 12px;
			}
			input {
				margin-left: .5em;
				text-align: center; 
				border: 0;
				border-bottom: 1px solid $medium-gray;
				box-shadow: 0 0 0 ;
				outline: 0;

				&.short {
					max-width: 75px;
				}
			}
		}
	}

	.field-group.field-group-numerical {
		label {
			@include clearfix();
			display: flex;
			margin-top: 1.5em; 
			margin-bottom: 1.5em;
			font-weight: 400;
			// justify-content: space-evenly ; 
			align-items: center; 
		    justify-content: space-between;
			width: 100%;
			@include media($medium-screen) {
				max-width: 35%;
				margin: 0 auto .5em;
				display: flex;
			
			}

			a, span , input {
				// display: inline-block;
				vertical-align: top;
			}
			span {
				font-size: 16px;
				margin-right: 1.5em;
				min-width: 30% ;
				// margin-bottom: 1.5em;
				margin-bottom: 0;
				padding: 0;
				@include media($medium-screen) {
					min-width: 0% ;
					margin-bottom: 0;
				}
			}
			input {

				float: right;
				// margin-left: .5em;
				font-family: $heading-font-family;
				text-align: center; 
				color: $medium-gray;
				border: 0;
				box-shadow: 0 0 0 ;
				outline: 0;
				max-width: 25%; 
				margin-right: 0;
				font-size: 2em;
				@include media($medium-screen) {
					max-width: 25%; 
					text-align: center;
					margin-right: 0;
				}
				&.short {
					max-width: 75px;
				}
			}


		}
		a.spinner.minus ,
		a.spinner.plus  {
			background: url('images/icon-spinner.svg') no-repeat center center ;
			display: inline-block;
			position: static;
			margin: 0 1em;

			opacity: 1;
			width: 1.5em;
			@include media($medium-screen) {
				width: 1.25em;
			}

			&.disabled {
				opacity: .3;
			}
		}
		.plus {

		}
	}

	.field-group-room:first-of-type .group-heading   {
		text-align: center; 
		margin-top:40px;
		@include media($medium-screen) {
			text-align:left;
			margin-top:0px;
		}
	}


	.warning {

		display: block; 
		background-color: $offwhite;
		text-align: center;
		padding: .5em;
		margin-bottom: .5em;
			@include media($medium-screen) {
				max-width: 820px ;
				margin: 0 auto .5em; 
				padding: 1.5em;
			}
		strong, p {
			text-transform: uppercase; 
			font-size: 1em;
			display: block;
			@include media($medium-screen) {
				display: inline-block;
				margin: 0 .5em;
				font-size: 1.2em;
			}
		}


		&.mobile-warning {
			margin-top: 20px;
		}

		span {
			font-family: $heading-font-family-italic;
		}

		&.hidden {
			display:none;
		}

		&.warning-link strong {
			text-transform: uppercase; 
		}

		&.warning-link a {
			padding: .5em 0 ;
			text-decoration: none;
			position: relative;
			display: block; 
			&:after {
				display: block;
				content: '';
				background: url('../assets/images/icon-chevron-down-black.svg') no-repeat right center ;
				width: 25px;
				height: 25px;
				transform: rotateZ(-90deg);
				position: absolute;
				right: .5em;
				z-index: 500;
				top: 4px
			}
		}

	}


	a.button {
		display: block; 
		border: 0;
		margin: 0 auto 1em ;
		font-size: 15px;
		font-family: $base-font-family;
		text-align: center; 
		cursor: pointer;
		@include media($medium-screen) {
			max-width: 360px;
		}

		span {
 			position: relative;
 			margin-top: -8px;
			margin-right: 1em;
			font-size: 25px;
			display: inline-block;
			vertical-align: middle;
			&:before {
				content: '';
				border-radius: 50px;
				border: 1px solid #000;
				position: absolute;
				width: 25px;
				height: 25px;
				display: block;    
				left: -4px;
    			bottom: 9px;
			}
		}

		&.add {
			margin-bottom: 4em;
		}
	}
 
	a.add-extra-to-room.disabled {
	  display:none!important;
	}

	.asterisk {
		font-size: 14px;
		padding: 1.5em;
		text-align: center; 
	}

	a.button.continue {

		font-family: $text-font-family;
		letter-spacing: 2px;
		background-color: $btn-gray;
		padding: 1em;
		text-align: center; 
		color: #eee;
		margin-bottom: 0;
		/*border: 1px solid $btn-gray ;*/
		position: fixed; 
		bottom: 0px;
		width: 100vw;
		z-index: 101;
		left:0px;

		@include media($medium-screen) {
			max-width: 360px;
			margin-bottom: 5em ;
			padding: .5em;
			position: relative; 
		}


		
		display: block ; 


		&.disabled {
			background-color: #B9BCBE; 
			pointer-events: none;
			cursor: default;

			&:hover {
				background-color: #B9BCBE;


			}
		}


		&:hover {
			color: $medium-gray;

		}

		&:focus, &:active, &:hover {
			&:before , & {
				/*background-color: $medium-gray ; 
				color: #fff; */
				background-color:#FCFAED;
				color:#000;
			}
		}

		&.loading {
			@include  media (min-width 320px max-width 680px){
				position:fixed !important;
			}
			position: relative;
			&:after{
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@include loadingAnimation();
			
		}

	}



}

.modal-booking .current-step .field-group.code {
	// transform: scale(5) !important ;
	text-align: center;
	margin-bottom: 4em;

	> label {
		font-family: $base-font-family;
		background-color: #fff;
		border-bottom: 1px solid #000;
		max-width: 420px;
		width: 100%;
		margin: 0 auto; 
		position: relative;
		padding: 0 2.5em;
		&:after {
			content: '';
			width: 20px;
			height: 20px;
			position: absolute;
			right: .5em;
			bottom: .7em ;
		}
		input {
			width: 100%;
			border: 0;
			font-size: 15px;
			text-transform: uppercase;
			margin-right: 0;
		}
	}
 
}

.modal-booking .select-room {
	display: flex;
	justify-content: bottom;
	cursor : pointer; 

	&.hidden {
		display:none;
	}

	a {
		font-family: $base-font-family;
		display: flex;
		align-items: center;
		flex: 1 ; 
		margin: 0;
		text-align: center;
		text-decoration: none;
		padding: 1.1em 1em;
		text-transform: uppercase;
		transition: .5s ease background-color; 
		position: relative;
		font-size: 15px;

		
		.tab-wrap {
			width: 100%;
		}

		.icon-check {
			position: absolute;
			// left: 25px;
			// top: 25px;
			margin-left: -25px;
			display: none;
			svg {
				width: 20px;
				fill: #383534;
			}
		}
		&.has-room {
			font-size: 12px !important;
			.icon-check { display: inline-block; }
		}

		.inner-text {
			position: relative;
			display: block;
			text-transform: none;
			margin: 0;
			font-style: italic;
		}

		&:after {
			content: "";
			position: absolute;
			background-color: #383534;
			width: 92%;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			height: 2px;
		}

		&:not(:first-child) {
			&:before {
				content: "";
				height: 40%;
				width: 1px;
				position: absolute;
				left: 0;
				top: 25px;
				background-color: #383534;
			}
		}

		&.selected {
			font-weight: bold;
		}

		&.selected:after {
			height: 4px;
		}

	}

}	



@include media(max-width $medium-screen) {
	.room-info table thead > tr > th:first-child {
		font-size: 1.5em;
		height: auto;
		min-height: 1em;
	}

}


.max-guests , .details , .amenity {
	// display: block ;

	font-size: 1.25em;

	img {
		max-width: 25px;
		display: inline-block;
		vertical-align: -3px;
		margin-right: .15em;
	}
}


.select-room{
	margin-top: 70px !important;
}

.select-room , 
.room-choice {
	padding: 0 1.5em 1.5em ;
	margin: 0 auto 1.5em;
	position: relative;

	> .booking-error.warning {
		margin-bottom: 3em;

		background-color: transparent !important;
		p {
			font-size: 25px;
			strong {
				font-family: $heading-font-family-italic;
				font-weight: 400;
				text-transform: none;
			}
		}
	}


	.image {
		width: 100%;
		float: left;
		position: relative;
		margin-bottom: 1.5em;
		@include media($medium-screen) {
			margin-bottom: 0;
			max-width: 35%;
			padding-right: 1.5em; 

		}
		img {
			width: 100%;  
			border:solid 1px #ccc;
			//box-shadow:#ccc 2px 2px 4px 2px;
		}

	}

	h4 {
		font-size: 1.6em;
		@include media($medium-screen) {
			font-size: 1.8em;
		}
		font-family: $heading-font-family-italic;
	}

	p {
		font-size: 1.2em;
	}

 
	.room-info {
		@include media($medium-screen) {
			padding-right: 2em;
		}

		td> span {
			font-family: $heading-font-family-italic;

		}

		span , a {
			display: block ;
			text-decoration: none;
		}

		> .room-detail-link {
			display: inline-block;
			text-decoration: none;
			position: relative;
			text-transform: uppercase;
			font-weight: bold;

			@include media($medium-screen) {
				font-size: 1.25em;
			}
			font-size: 1.1em;

			&:after {
				content: '';
				transform: rotateZ(270deg);
				background: url('images/icon-circle-arrow.svg') no-repeat center center;
				width: 20px;
				height: 20px;
				position: absolute;
				right: -2em;
				bottom: .25em ;
				transition: .2s ease right; 
			}
			&:hover:after {
				right: -2em;
			}
		}

	}


	.rates button, div.toggle-calendar {
		// color: #fff; 
		float: right;
		margin: 1.5em auto ;
		padding: 1.2em 1.5em;
		width: 100%;
		border: 1px solid $btn-gray;
		color: $medium-gray ; 
		background-color: #fff;  
		font-weight: 400;
		font-size: 1.1em;
		&:hover {
			color: $btn-gray;
			background-color: #fff;  
		}
		&:focus {
			outline:none;
		}

		&:after {
			background: url('../assets/images/icon-chevron-booking-btn-gray.svg') no-repeat center center;
			content:'';
			width: 16px;
			height: 16px;
			display: inline-block;
			float:right;
			margin-top: -2px;
			transform: rotateZ(180deg);
		}

		&.active {
			background-color: $btn-gray;
			color: #fff;
			&:hover {
				color: #fff;
				background-color: $medium-gray;  
			}
		}

		&.active:after {
			background: url('../assets/images/icon-chevron-booking-btn-white.svg') no-repeat center center;
			transform: rotateZ(0deg);
		}

		@include media($medium-screen) {
			float: none;		
		}

		&.loading {
			position: relative;
			&:after{
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@include loadingAnimation();
		}

	}

	table button , table a.button {
		float: right;
		background-color: $btn-gray;
		color: #fff;
		padding: 1.2em 2.5em;
		font-weight: 400;
		font-size: 1.1em;

		&.disabled {
			background-color: #BBBCBB;
			border: 0;
		}

		&:hover {
			background-color: $medium-gray;
		}

		@include media($medium-screen) {
			width: 100%;
		}

		&.loading {
			position: relative;
			&:after{
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@include loadingAnimation();
		}
 
	}

	.room-info table th {
		text-transform: uppercase;
		font-size: 1.25em;
		vertical-align: top;
		&:nth-child(1) {
			width: 25%;
		}
		&:nth-child(4) {
			width: 10%;
		}
		&:nth-child(5) {
			width: 23%;
		}
	}


	table th.wider {
	    
	    width: 100%; 

	    @include media($medium-screen) {
    		display: block;
	    }

	    a {
	    	float: left;
	    	width: 100%;
	    	@include media($desktop) {
	    		min-width: 400px;
	    	}
	    }

	}

	table tr {

		@include media($desktop) {
			border: 0;
		}

		// border-bottom: 1px solid #ccc;
	}
 
	th[scope="col"] ,
	table tr.row {
		border-bottom: 1px solid #ccc;
	}


	table tr.row:last-child {
		border-bottom: 0;
	}


	th.collapse {
		display: none;
		@include media($medium-screen) {
			display: table-cell;
		}
	}


	th > h5 + ul {
		display: none;
		@include media($medium-screen) {
			display: block;
		}
	}

 
	table thead span {

		display: block; 
		text-transform: none;
		font-size: .75em;
	}

	h5 {
		text-transform: none;
		font-size: 1.15em;
		font-family: $heading-font-family-italic;

	}

	ul , ul li {
		text-transform: none;
		list-style-type: none;
	}


	table tr .total {
		font-size: 1.35em; 
	}

}




.room-choice .room-row a.button.rates { 
	text-decoration: none;
	background-color: $offwhite;
	text-align: center;
	padding: 1em 2em;
	color: $medium-gray;
		@include media($large-screen) {
			min-width: 400px;
		}

}

.add-room-to-basket.booked {
	background-color: $medium-gray;
	color: #fff;
	border: 1px solid $medium-gray;
	opacity: 1;

	&:hover {
		background-color: #fff;
	}

}

.booking-conditions{
	color:$medium-gray;
	border-bottom: 1px solid #ccc;
	padding-bottom: 15px;
	font-size:0.9em;
}


.desktop-table {
	display: none;
	@include media($medium-screen) {
		display: table;
	}
}

.mobile-table {
	display: block;
	@include media($medium-screen) {
		display: none;
	}
	width: 100%;

	h4 {
		font-size: 1em;
		
	}

	h5 {
		font-size:1.4em;
	}

	> h4:first-child {
		//border-bottom: 1px solid #ccc;
		//padding-bottom: 15px;
	}
 
	ul li {
		display: list-item;
		margin-left: 1.5em;
		list-style-type: disc  ;
	}

	tbody {
		width: 100%;
		display: table; 
	}

	tbody > tr {
		width: 100%; 
		border-bottom: 1px solid #D0D0D0;
		display: table-row;
	}

	tbody > tr:first-child > td {
		// font-weight: bold; 
		font-size: 12px;
		text-transform: uppercase;
		font-family: $base-font-family-bold ;
		padding-right: .5em;
		> span {
			text-transform: lowercase;
			// font-weight: normal;
			font-family: $base-font-family;
		}
	}

	&.table tr > td {
		padding: .25em 0;
		@include media($small-screen) {
			padding: 1em 0;
		}
		width: 33%;

	}

	&.table tr.fullwidth , tr.fullwidth > td {
		width: 100%;
		display: block;
		clear: both;
		// display: table-row; 
		> a.button {
			display: block;
			width: 100%;
			font-size: 14px;
		}
	}


	& + div button {
		width: 100%;
		display: block; 
	}




	a , button {
		width: 100%; 
	}

	> .button-book {
		color: #fff;
		background-color: $btn-gray;
		margin-bottom: 2.5em;
	    padding: 1.2em 1.5em;
    	font-weight: 400;
    	font-size: 1.1em;

		&:hover {
			background-color: $medium-gray;
		}

		&.loading {
			position: relative;
			&:after{
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@include loadingAnimation();
		}
	}

}


.icon-process {
	cursor: pointer; 
	max-width: 30px;
	height: 30px;
	margin: 0 auto;
	// display: block; 
}

.room-row .policies {
	display: none;
	position: absolute;
	background-color: #fff;
	box-shadow: $glow;
	padding: 1em; 
	z-index: 1500;
	max-width: 100%;
	@include media(max-width $small-screen) {
		left: 1.5em;
		right: 1.5em;
	}
	@include media($small-screen) {
		left: 1.5em;
		right: 0;
		max-width: calc(100vw - 3em);
	}

	@include media($medium-screen) {
		left: auto;
		right: auto;
		max-width: 325px;
	}

	li {
		list-style-type: none;

		border-bottom: 1px solid #eee;
		// margin-bottom: .5em;
		margin: 0;
		padding: .5em;
		font-size: 1.125em;

		@include media($small-screen) {
			padding:0;
		}

		&:last-child {
			border: 0;
		}
	}
	&.visible {
		display: block;
	}
}


.unavailable image, 
.unavailable button {
	opacity: .5;
	// pointer-events: none; 
	

	button {
		// pointer-events: none; 
	}
}

// .room-row.already-selected{
// 	display:none;
// }

.room-row.unavailable {
	img , .rates button.show-hide {
		opacity: .5;
		transition: .5s ease opacity;
	}

	.image {
		position: relative;
	}

	.message {
		position: absolute;
		z-index: 500;
		left: 0;
		top: 0;
		right: 0;
		max-width: 100%;
		background-color: #000;
		color:#fff;
		font-family: $heading-font-family;
		text-align: center;
		padding: .25em; 
		@include media($medium-screen) {
			margin-right: 1.5em;
		}
	}
}


// Room information structures

.select-room , 
.manage , 
.room-select-container {
	max-width: 1110px;
	margin: 0 auto ;
}

.room-row {
	display: block ;
	width: 100% ; 
	@include clearfix(); 

	.rate-description {
		font-size: 1em;
		text-transform: none;
		font-weight: 400;
	}

	.mobile-table .rate-description {
		font-size: 1em;
	}

	
	border-bottom: 1px solid $medium-gray; 
	margin-bottom: 2.5em; 

	&,div {transition: .2s ease all; }

	.room-info {
		padding: 0;
		@include media($medium-screen) {
			padding: 0 0 2em;
			max-width: 35%; 
			float: left;
		}
	}

	.rates {
		@include media($medium-screen) {
			width: 100%;
			max-width: 23%; 
			float: right;
			padding-top: 2.15em;
		}
		@include clearfix(); 

		> span {
			font-family: $heading-font-family-italic;
			font-size: 1.25em;
			
		}

		.per-night {
			font-size: 1em;
		}

		strong {
			font-size: 2em;
			display: block ;
			margin:0;
			font-family: $heading-font-family-italic;
		}

	}

	.room-info.table {
		display: table ;
		float: none;
		width: 100%;
		max-width: 100%;
		padding: 0 ;
		border-top: 1px solid #666;
		padding-top: 1.25em;
		@include media($medium-screen) {
			padding: 0;
			border:0;
		}
	}

	.unavailable-room{
		line-height: 0;

		.image-wrap{
			width:100%;
			overflow:hidden;
			position:relative;
		}

		.na-overlay{
			position:absolute;
			height:100%;
			width:100%;
			background-color:rgba(255,255,255,0.5);
			top:0;
			margin:0;
		}

		.na-message{
			text-align:center;
			background-color:black;
			width:100%;
			display:block;
			padding:20px;
			color:white;
		}
	}

}


// Table arrangement 

.room-info.table {
	td {
		padding: 1em 0 .5em;
	}
}


.room-select-container {

		@include media($medium-screen) {
			margin-top: 2.5em;
		}
		

		.room-choice {
			display: none;
		}

		.room-choice.selected {
			display: block; 
		}

		.room-row {
			// margin-top: 1.5em;
			@include media($medium-screen) {
				// border-bottom: 1px solid $medium-gray;
				// padding-bottom: 3em;
				// margin-bottom: 2.5em;
			}
		}

}


.customiser {

	.container:after{
		content: '';
		width: 100%;
		clear: both;
		display: block; 
	}


	h3 {
		font-size: 20px;
	    margin: 0 1.5em 1.25em;
		padding-bottom: .5em; 
		border-bottom: 1px solid $medium-gray; 
		clear: both;
	}

	> .additions > .warning {
		margin-bottom: 1.5em;
		clear: both;
	}

	> .additions > .grid {
		@include outer-container();
	}

	.additions > .grid .item {
		position: relative;
		text-align: center;
		border: 2px solid #eee;
		// @include span-columns(12);
		padding: 1.5em;
		transition: .5s ease height ;
		
		// margin: 0 1.5em;
		margin: 0 auto ;
		max-width: calc( 100% - 3em ) ;
		margin-bottom: 1.5em;
		min-height: 280px;


		&.star {
			background: url('../assets/images/icon-star.png') no-repeat 98% 2%;
		}

		&.star-filled {
			background-image: url('../assets/images/icon-star-filled.png');
		}

		.description {

			h4 {
				font-family: $heading-font-family;
				font-size: 1.25em;
			}

			.extra-price {
				font-size: 1.25em;
				font-weight: bold;
			}

			p {
				font-size: 1em;
			}
		}

		.extra-image {
			@include media($medium-screen) {
				height: 145px;
			}
			height: 135px;

		}

		@include media($medium-screen) {
			min-height: 360px;
			margin: 0;
			@include span-columns(6);
			@include omega(2n);
			margin-bottom: 2.5em ;
		}

		.image {
			// max-width: 85%;
			margin: 0 auto;
		}

		.add {
			cursor: pointer; 
			background-color: $btn-gray;
			color: #fff;
			margin-top: 1.5em;
			// padding: .5em;
			font-size: 16px;
			text-transform: uppercase;
			transition: .2s ease all;
			&:focus, &:hover {
				background-color: $medium-gray;
				color: #fff;
			}

			span.fakebutton {
			    display: block;
			    margin: 0;
			    text-align: center;
			    padding: .5em 0;
				line-height:1;
			}

			.overlay {

				display: none;
				background-color: $btn-gray;
				color: #fff;
				position: absolute;
				opacity: 1;
    			transform: none;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				// display: flex; 
				vertical-align: middle;
				align-items: center;
				margin: 0; 
				> div {
					// display: block; 
					// margin-bottom: 1.5em;
					width: 100%; 
					padding: 1.2em 2.1em;
				}

				.overlay-title {
					font-family: $heading-font-family-italic;
					text-transform: none;
					font-size: 16px;
					max-width: 90%;
					display:block;
					margin-left: 5%;
					margin-bottom: 1.5em;
				}


				a:not(.close) {

					color: #fff;
					border: 1px solid #fff;
					padding: .5em;
					display: block; 
					max-width: 85%;
					font-size: 14px;
					margin: 1em auto .75em ;
					text-decoration: none;

					&:hover {
						border: 1px solid $offwhite; 
						color: $btn-gray;
						background-color: $offwhite;
					}

				}

				.close img {
					position: absolute;
					color: #fff;
					top: .3em;
					right: .3em;
					border: 0;
					display: block;
					margin: 0 ;
					padding: 0;
					height: 22px;
					width: 22px;
					font-size: 2em;
				}

			}	
		}

	}
}

.customiser {
	.additions {
		margin-bottom: 2.5em ;
		@include media($desktop) {
			// float: left;
			// max-width: 66% ;
			@include span-columns(7);
		}
	}

	.reservation-summary {
		@include media($desktop) {
			// max-width: 33%;
			// float: right;
			@include span-columns(5);
		}
	}

}


.finalise-booking  {
	.finalise {
		margin-bottom: 2.5em ;
		@include media($desktop) {
			// float: left;
			// max-width: 66% ;
			@include span-columns(7);
		}

		h3 {		
			font-size: 20px;		
		    border-bottom: 2px solid $hightlight-gray;		
		    font-family: "Romain Headline Italic",serif;		
		    text-transform: none;		
		    margin: 0 auto 1.25em auto;		
		    padding-bottom: .5em;		
		    clear: both;		
		    text-align: left;		
		}

		input:not([name=country]), select {		
			/*padding-top: 1.7em;		
			padding-bottom: 0.8em;	*/	
		}

		.room-guests {		
			margin-bottom: 20px;		
			&.same-as-booker .booked-room {		
				border-bottom: 2px solid #f5f5f5;		
				padding-bottom: 15px;		
				margin-bottom: 20px;		
			}
			.guest-close-button {
				text-decoration:none;
				font-size: 1.5em;
				line-height: 1em;
			}	
				
			.booked-room {		
				display: flex;		
				justify-content: space-between;		
						
				margin-bottom: 20px;		
				span, label {		
					font-style: italic;		
					font-weight: 700;		
				}		
				label {		
					display: block;		
					position: relative;		
					padding-left: 35px;		
					margin-bottom: 12px;		
					cursor: pointer;		
					user-select: none;		
					input {		
						position: absolute;		
						opacity: 0;		
						cursor: pointer;		
						&:checked ~ .checkmark {		
							&:after { display: block; }		
						}		
					}		
					.checkmark {		
						position: absolute;		
						top: 0;		
						left: 0;		
						height: 25px;		
						width: 25px;		
						border-radius: 50%;		
						border: 1px solid $medium-gray;		
						&:after {		
							content: "";		
							position: absolute;		
							display: none;	

							bottom: 0;		
							top: 0;		
							left: 0;		
							right: 0;		
							width: 15px;		
							height: 15px;		
							margin: auto;		
							border-radius: 50%;		
							background-color: $medium-gray;		
						}		
					}

				}		
			}		
		}

		.add-additional-guest {		
			border: 2px dashed #f5f5f5;		
			background-color: #fff;		
			width: 100%;		
			position: relative;		
			color: $dark-gray;		
			font-size: 16px;		
			font-family: AvenirNextLTPro-Regular,sans-serif;		
			margin: 0 0 40px 0;		
			img {		
				position: absolute;		
				height: 16px;		
				left: 17%;		
				@include media($medium-screen) { left: 25%; }		
			}		
			&:focus { outline: none; }		
		}
	}

	.reservation-summary {
		@include media($desktop) {
			// max-width: 33%;
			// float: right;
			@include span-columns(5);

			&.scrolled {		
				position: fixed;		
				margin: auto;		
				width: 100%;		
				max-width: 1120px;		
				height: 0;	

				.rsrv-wrap {		
					float: right;		
					width: 40.29137%;		
				}		
			}
		}

		#terms-box {
			//display:none;
		}

		.add-coupon {		
			position: relative;		
			background-color: #fff;		
			color: #000;		
			margin-bottom: 15px;		
			img {		
				width: 11px;		
				position: absolute;		
				margin: auto;		
				left: 25%;		
			}		
		}
		.coupon-field-group {
			position: relative;		
			margin-bottom: 15px;		
			display: none;		
			&.show { display: flex; }		
			input, button {		
				margin: 0;		
				font-family: AvenirNextLTPro-Regular,sans-serif;		
				font-size: 14px;		
			}		
			input {		
				width: 70%;		
				padding: 15px 20px;		
			}		
			button {		
				min-width: 30%;		
				width: 30%;		
			}
		}
	}

}

.finalise-booking .finalise {
	& , form {padding: 0; margin-top :0;}
	& + .reservation-summary {
		margin-bottom: 2em;
	}

	input, select {		
		font-family: AvenirNextLTPro-Regular,sans-serif;		
	}

}

.reservation-summary, .additions {

	margin: 0 auto;
	max-width: calc(100% - 1.5em); 
	@include media($small-screen) {
		width: 100%;
	}


//	font-size: 16px;

	h3 {

		font-size: 20px;
		border-bottom: 2px solid $hightlight-gray;
		font-family: $heading-font-family-italic;		
		text-transform: none;
		margin-bottom: 1.25em;
		margin-left: 0;
		padding-bottom: .5em;  
		clear: both;

	}


	.guest {
		display: flex; 
		align-items: baseline ;
		border-bottom: 1px solid $medium-gray;
		img {
			margin-right: .5em; 
			max-height: 20px;
			width: auto; 
			display: inline-block;
		}

		span {
			display: inline-block;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: bold; 
		}

	}


	.dates > div,
	.coupon-discount > div {
		display: flex; 
		justify-content: space-between ;
		margin-bottom: .5em;

		span , strong {
			display: block;
			margin-bottom: 0;
		}

		strong {
			width: 100%; 
			flex: 1 ;
			text-transform: uppercase;
			font-weight: 400;
		}

	}

	.coupon-discount > div {		
		color: red;		
	}

	.total {
		margin-top: 1em;
		text-transform: uppercase;
		border-top: 2px solid $hightlight-gray;
		padding-top: .5em;
		font-weight: bold; 
		justify-content: space-between; 
		display: flex;
		font-size: 1.15em;
	}

	> .summary-form > label + .asterisk {
		text-align: left ;
		display: block;
		margin: 1em 0 1.5em; 
		padding: 0;
	}

	.checkbox-wrap {
		// display: flex;
		margin-bottom: 2.5em;
		span {
			// vertical-align: middle ;
			text-transform: uppercase;
			font-family: $base-font-family-bold;
			padding-top: .5em;
			display: block;
			float: left;
		}

		input#terms {
			margin-bottom: 0;
			// vertical-align: middle ;
			float: right;
			display: block;
			border-radius: 5px;
			border: 2px solid #c4c4c4 ;
			outline: 1px solid #fff ;  
		    padding: 11px;
		    box-shadow: 0 0 3px #fff;
			&:checked {
				box-shadow: 0 0 3px #fff;
			    // padding: 12px;
			    background: #383634 url(../assets/images/icon-terms-tick.svg) no-repeat center center;
			    background-size: 75%;
			    border: 2px solid #383634;
			}
		}


	}

	.remove-extra-from-cart img,
	.remove-coupon img {
		width: 12px;
		height: 12px;
		margin-right: 1em;
	}


	form button {
		margin-bottom: 2em;
		width: 100%; 
		letter-spacing: 1px;
		background-color: $btn-gray;
		color: #fff;
		font-size: 16px;
		padding:0.5em 0px;
		font-family: AvenirNextLTPro-Regular,sans-serif;
		&.disabled {
		    background-color: #c4c4c4;
		    border: 0;

		    &:focus , &:hover {
				background-color: #c4c4c4;
			}
		}

		&.complete-booking.disabled {
			cursor: not-allowed;
			pointer-events: none;
			&:focus { outline: none; }
		}

		&:focus , &:hover {
			background-color: $medium-gray;
			color: #fff;
		}
	}

}

.summary-form {
	
	.custom-select {
			background: transparent url(../assets/images/icon-customise-select.png) no-repeat;
			background-position: right 10px center;
	}

	max-width: 100%;


	input, select {		
		font-family: AvenirNextLTPro-Regular,sans-serif;		
	}
}


.ui-corner-all.ui-state-hover {
	background-color: #fff;
	border: 0;
	&:hover {
		transform: scale(1.05);
	}
}

.date-info {

	input {
		font-family: $text-font-family;
	}
	
	margin-bottom: 2em;

	@include media($large-screen) {
		display: flex; 
	}

	display:block;
	justify-content: space-between ;

	label {
		align-items: center;
	}

	input#nights {
		max-width: 100px;
	}

}


.login , .signup , .finalise  {
	max-width: 640px;
	margin: 0 auto; 
	padding: 3em  1.5em ;
	text-align: center; 
	form#email-form {
		margin-bottom: 2em;
	}
	button {
		margin-top: 1.5em; 
		background-color: $btn-gray;
		color: #fff; 
		padding: .9em ;
		font-family: AvenirNextLTPro-Regular,sans-serif;

		&:hover {
			background-color: $medium-gray;
		}

	}

	p {
		@include clearfix(); 
	}

	.forgot-password {
		float: left;
		margin-bottom: 1em;
		// display: block;
		color: rgba(0,0,0,0.5);
		border-bottom: 1px solid rgba(0,0,0,0.5); 
		clear: both; 
		// @include clearfix();
		text-decoration: none;
		&:hover {
			color: #000;
			border-color: #000;
		}
	}

	.access p > .asterisk {
		float: right;
		display: block; 
		padding: 0;
	}

	.button-container {
		clear: both ;
		padding: 5em 1.5em 0; 
		position: static;
		button {
			padding: .5em 2em;
			max-width: 345px;
			display: block; 
			margin: 0 auto; 
			width: 100%; 
		}
	}

}

.finalise form {
	text-align: left;
}

.room-select-heading {
	padding: .5em;
	text-align: center;
	text-transform: uppercase;
	font-size: 16px;
	background-color: #F9F5DB ; 
	// border-radius: 8px;
	margin-bottom: 2.5em;
}

.confirm  {
	> .changing .room-info {
		display: none;
	}

	.rates {
		float: right;
		padding:0 0 2em;

		strong {
			font-family: $base-font-family-bold;
			font-size: 16px;
			text-transform: uppercase;
			display: block;
			text-align: left;
		}

		table {
			
			margin: 0;
		}

		td {
			padding:0 0 .5em;
		}

		td:last-child {
			text-align: right;
		}
	}

}

.changing .rates {
	@include media($desktop) {
		max-width: 45%;
	}
}


.confirm .rates button:after , 
.manage .rates button:after {
	display: none;
}

a.print {
	text-decoration: none;
	&:after {
		content:'';
		background: no-repeat center right; 
		display: inline-block;
		background-image: url('../assets/images/icon-print.svg');
		width: 25px;
		height: 25px;
		margin-left: 1em;
		vertical-align: top;
	}
}

.confirm {
	.room-info {
		display: none;;
	}
	.rates{
		@include media($desktop) {
			max-width: 55%;
		}
	}
}

.summary.container {
	max-width: 1070px;
	margin: 0 auto;
	padding: 0 1.5em ;
	@include media($desktop) {
		padding: 0;
	}
	tr:first-child > td {
		font-size: 1.5em;
		@include media($desktop) {
			font-size: 2em;
		}
		font-family: $heading-font-family;
	}

	tr:nth-child(2) td {
		padding: 1em 0 ;
	}

	td {
		vertical-align: middle;
	}
	td:last-child {
		text-align: right;
	}

}

.hidden{		
	display: none!important;		
}

.mb-0{
	margin-bottom: 0 !important;
}

.extra-room-number{
	font-size: .8em!important;
}

.finalise-booking{
	&.add-flex{
		@media screen and (min-width: 1279px){
			display: flex;
		}
		.finalise{
			@media screen and (min-width: 1279px){
				float: unset;
				margin-right: auto !important;
				margin: unset;
			}
		}
	}
}

.add-extra-to-room, #add-basket-notes, .complete-booking{
	&.loading {
		position: relative;
		&:after{
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
		}
		@include loadingAnimation();
	}
}

.booking-toggle{
	span{
		&.loading {
			position: relative;
			&:after{
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@include loadingAnimation();
		}
	}
}

.booking-toggle:hover span.loading:after {
	background: url(images/loader-dark.svg) no-repeat center center;
}

.checking.instruction {
	p{
		&.loading {
			position: relative;
			&:after{
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@include loadingAnimation();
		}
	}
}
