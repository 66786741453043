.compare-slider {
	text-align: center;
	margin:0 auto 12em;
	max-width: $max-width;
	position: relative;
	padding: 0;
	overflow: visible;

	.swiper-slide {
		min-height: 22vh; 
		display: flex;
		align-items: center; 
		text-align: center;
		margin:0;
	}
	@include media($large-screen) {
		border-top: 1px solid #000;
		padding-top: 45px; 
		padding-bottom: 25px; 
	}

	&:before , &:after {
		left: 0;
		right: 0;
		position: absolute;
		content:'';
		// width: 85vw;
		@include media($large-screen) {
			border-top: 1px solid #000;
			width: 100%;
		}
	}
	&:before {
		top: -5px;
	}
	&:after {
		bottom: -5px;
		top: auto;
	}
}

.compare-slider h2 {
	width: 100%; 
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 50;
	position: relative;
	min-height: 40px;
	font-size: 1.6em;

	span {
		background: #fff;
		position: absolute;
		max-width: 220px;
		left: 0;
		right: 0;
		z-index: 55;
		margin: 0 auto ;
		top: -.25em;
	}

	@include media($large-screen) {
		span {
			background: #fff;
			max-width: none;
			position: relative; 
		}
		position: absolute;
		background: #fff;
		display: inline-block;
		top: -.5em;
		max-width: 220px;
	}
	&:before , &:after {
		z-index: 30;
		content:'';
		width: 85%;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		height: 5px;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 5px;
		@include media($large-screen) {
			display: none;
		}
	}
}

.compare-slider {

	.swiper-button-prev,  .swiper-button-next {
		
		top: auto;
		// position: relative;
		z-index: 250;
		background-image: url('images/icon-chevron-circle.svg');
		background-size: cover ;
		margin-bottom: 0;
		bottom: 45%; 
		width: 30px;
		height: 30px;
		transition: $base-animation ; 
		display: none;
		@include media($large-screen) {
			// opacity: .85;
			display: block;
			padding: 1.5em;
			bottom: calc( 50% - 30px ) ;
			width: 30px;
			height: 30px;
			&:hover {
				opacity: 1;
			}
		}


	}

	.swiper-button-prev {
		transform: rotateZ(0deg);
		margin-left: -35px;
	}
	.swiper-button-next {
		transform: rotateZ(180deg);
		margin-right: -35px;
	}

	&:after {
		content:'';
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		height: 5px;
		display: block;
		max-width: 80vw;
		margin: 0 auto;
		left: 0;
		right: 0;
		bottom: -35px;
		@include media($large-screen) {
			bottom: -25px;
			// display: none;
		}
	}

}