.grid {
	// width: 100%;
	max-width: $max-width;
	margin: 0 auto 3.5em;
	padding: 0 1.5em;

	.container {
		padding: 0;
		@include clearfix(); 
		@include media($medium-screen) {
			margin: 0 auto 4em;
			align-items: center ;
			display: flex; 
		}
	}

	.grid-item {
		text-align: center; 
		margin: 0 0 2em;
		width: 100%; 
		@include media($medium-screen) {
			float: left;
		}

		strong {
			display: block;
			margin: .5em 0;
			font-family: $heading-font-family; 
			font-weight: bold;
			font-size: 1.25em;
		}

		p {
			font-size: 1em;
			line-height: 1.65em;
		}

		p, strong {
			padding: 0 1.5em;
		}

		img {
			max-width: 85%;
			margin: 0 auto ;
			@include media($large-screen) {
				max-width: 100%; 
			}
		}

	}

	@include media($medium-screen) {
	
		&.four .grid-item {
			width: 50% ;
		}

		&.three .grid-item {
			margin-bottom: 0;
			width: 33%;
			padding: .5em;
			display: inline-block;
		}


		&.four , &.three {
			.container {
				margin-bottom: 1em;
			}
		}

	}

	@include media($large-screen) {

		&.four .grid-item {
			width: 25%;
		}
		&.three .grid-item {
			width: 33%;
		}
	}

}

.grid .container h2 {
	@include lined-heading();
	font-size: 1.6em;
}