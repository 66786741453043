.card {
	position: absolute;
	bottom: -2.5em;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: #fff;
	z-index: 50;
	background-color: $medium-gray;
	text-align: center; 
	padding: 1em 1.25em;
	max-width:  85vw;
	@include media($large-screen) {
		margin: auto;
	    max-width: none;
	    width: auto;
	    left: 50%;
	    transform: translateX(-50%);
	    right: auto;
	    padding-left: 9em;
	    padding-right: 9em;
	}
	
	h2, h3 {
		padding: 0;
		margin: 0;
		line-height: 1em;
	}

	h2 {
		font-size: 1.6em;
		@include media($medium-screen) {
			font-size: 2em;
		}
	}

	h3 {
		text-transform: uppercase;
		font-size: 1em;
		letter-spacing: .05em;
		margin-bottom: .4em;
		@include media($medium-screen) {
			
		}
	}

}

.page-meetings .card {
	@include media($large-screen) {
		max-width:  580px;
	}
}