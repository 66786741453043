html {
	color: $base-font-color;
	font-family: $base-font-family;
	font-size: 80%;
	line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-family;
	font-weight: normal;
	font-size: modular-scale(1);
	line-height: $heading-line-height;
	margin: 0 0 $small-spacing;
	color: inherit;
	// font-style: italic;
}

h1 , h2 {
	font-family: $heading-font-family-italic;
	font-weight: normal;
}



h3 {
	font-family: $base-font-family;
	text-transform: uppercase;
	font-weight: medium;
}

h4 {
	font-family: $heading-font-family;
	font-size: 1.1em;
}

h5 {
	font-family: $heading-font-family;
	font-size: 1.05em;
}

h6 {
	font-family: $heading-font-family;
	font-size: 1.015em;
}

strong {
	font-weight: normal;
	font-family: $base-font-family-bold; 
}

a {
	font-size: inherit;
	color: $action-color;
	text-decoration-skip: ink;
	transition: color $base-duration $base-timing;

	&:hover {
		color: shade($action-color, 25%);
	}

	&:focus {
		outline: $focus-outline;
		outline-offset: $focus-outline-offset;
	}
}

p {
	margin-top: 0;
	font-size: 1em;
	line-height: 1.65em;
	@include media($large-screen) {
		font-size: 1.25em;
		line-height: 150%;
	}
}

.discovery {
	ul li, ol li {
		font-size: 1em;
		line-height: 1.65em;
	}
}

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $base-spacing 0;
}

blockquote {
	font-style: initial;
	font-family: $heading-font-family-italic-cuts ;
	// font-style: italic;
	font-size: 1.25em;
	margin:0 auto 3em;
	width: 100%;
	padding: 0 1.25em 2em;
	max-width: 930px ;
	@include media($large-screen) {
		margin: 0em auto 2.25em;
		padding:0;
		font-size: 1.8em;
	}
}

button {
	font-size: 1.25em;
}

.inverse {
	color: #fff;
}

.section-heading {
	margin-bottom: 0;
	// font-style: italic; 
	font-weight: normal;
	font-size: 1.625em;
	padding: .25em 0;
	text-align: center;
	font-family: $heading-font-family-italic-cuts ;
	@include media($large-screen) {
		font-size: $heading-large;
		margin-bottom: 1em;
	}
}

.page-room {
	@include media(max-width $medium-screen) {

	.two-col .container:first-child {
		padding-top: 0;
	}
	.section-heading {
			font-size: 1.75em;
			line-height: 1.5em;
		}
	}

	@include media($medium-screen) {
		.section-heading {
			font-size: 2.85em;
			line-height: 1.5em ;
		}
	}

}



.page-contact {


	.wide-image .title > h1 {
		@include media($medium-screen) {
			font-size: 1.1em
		}
	}

	.two-col main h3:first-child {
		font-size: 1.25em;
	}

}

.full-screen p {
	margin-top: 1.25em;
	margin-bottom: 3.5em;

}

.complete h2,
.bg-slider h2 , 
.discovery h2, 
.full-screen h2 {
	text-shadow: $text-shadow;
	font-weight: normal;
	font-family: $heading-font-family-italic-cuts ;
	font-size: 2.15em;
	@include media($medium-screen) {
		font-size: 3.725em;
	}

	@include media(min-width 1650px max-width 1681px) {
		font-size: 3em;
	}

}

.slider h2 {
	font-family: $heading-font-family-italic-cuts ;
}

.discovery h1 {
	text-shadow: 0 0 0;
}

@mixin lined-heading() {
	max-width: $max-width;
	text-align: center; 
	width: 100%;
	position: relative;
	&:before  {
		height: 5px;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 50%;
		bottom: 0;
		width: 100%;
		max-width: 85vw; 
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		transform: translateY(-50%); 
		@include media($medium-screen) {
			max-width: 100%;
		}
	}

	span {
		background-color: #fff;
		padding: .5em;
		display: block;
		margin: 0 auto;
		position: relative;
		max-width: 170px;
		// bottom: 1em;
		@include media($medium-screen) {
			max-width: 250px;
		}
	}
}

.first-char p:first-of-type  {
	&::first-letter {
		font-size: 2em;
		font-family: $heading-font-family  ;
	}
}


.two-col p.italic {
	font-size: 1.6em;
	line-height: 1.65em ;
	@include media($medium-screen) {
		font-size: 1.4em;
		line-height: 1.5em;
	}
	font-style: italic;
	font-family: $heading-font-family-italic-cuts ;
}