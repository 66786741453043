footer {
	font-family: $heading-font-family-italic;
	background: $medium-gray;
	color: #fff; 
	font-size: 1em; 
	margin:0;
	// padding: 2.75em 0 12em;
	// min-height: 50vh;
	min-height: 980px;
	@include media($medium-screen) {
		padding: 5em 0;
	}
	
	a , li , p {
		color: #fff;
		margin-top: 0;
		// font-size: 1.25em;
		// font-style: italic;
		list-style-type: none;
		// font-family: $heading-font-family;
		text-decoration: none;
	}

	ul li {
		margin-bottom: 1em;
	}

	.container {
		margin: 0 auto;
		max-width: 85vw; 
		@include media($large-screen) {
			max-width: 1440px;  
		}
		padding: 2em 0 ;
	}
}

footer > nav.container {
	max-width: 85vw;
	@include clearfix();
	text-align: center;

	@include media($medium-screen) {
		text-align: center; 
	}
	@include media($large-screen) {
		text-align: left; 
		max-width: 1440px;
		display: flex; 
		flex-wrap: nowrap;
	}
	@include media($hires) {
		max-width: 75% ;
	}

	@include media(min-width 1028px max-width 1440px) {
		max-width: 90%;
	}

	.logo , .menu , .address, .connect {
		width: 100%;
	    // font-size: .65rem;

	    

		&.logo img {
			max-width: 50%;
			margin: 0 auto;
			display: none;
		    @include media($large-screen) {
		    	display: block;
		    }
			@include media($large-screen) {
				max-width: 100%;
				margin: 0;
			}
		}
	}

	@include media($medium-screen) {

		

	}
	@include media($large-screen) {

		text-align: left;

		.logo { 
			flex: 1 20%;
			padding-right: 3%;
			img {
				max-width: 90%; 
				margin: 0 auto ;
				display: block;
			}
		}

		.menu { 
			flex: 1 20% ; 
			// padding-right: 2.5%;
			padding-left: 7%; 
		}
		.address { 
			flex: 1 20% ; 
			padding-right: 2.5%;
			padding-left: 2.5%;
		}
		.connect {
			flex: 1 20% ; 
			padding-right: 0;
			padding-left: 5%; 
			// float: right;
		}

	}

	ul {
		margin: 0; 
	}

	address {
		& , a {

			padding: 1em 0 .25em ;
			font-size: 1.1em ;
			font-family: $base-font-family;
			font-style: normal;
			@include media($medium-screen) {
				padding: 1em 0 2em ;
				font-size: 1.1em;
			}			
			@include media($large-screen) {
				font-size: 1.2em;
			}
			@include media($desktop) {
				font-size: 1.2em;
			}
		}
		
		@include media($large-screen) {
			padding: 0;
		}

		> a {
			margin-top: 1em;
			margin-bottom: 0;
			display: block;
			@include media($medium-screen) {
				font-size: 1.1em;
				display: inline-block;
			}			
			@include media($desktop) {
				font-size: 1.1em;
				display: inline-block;
			}
		}


		& {
			padding: 0 ;
		}

	}

	li a {
		font-size: 1em;
		line-height: 1.5em;
		@include media(min-width 1679px max-width 1681px) {
			font-size: 1.1em;
		}
		@include media($desktop) {
			font-size: 1em;
		}
		font-weight: normal;
		font-family: $heading-font-family-italic-cuts ;
		font-style: initial;
		position: relative;

		&:hover {
			color: #fff;
		}

		&:before { // Underline 
			position: absolute;
			content: '';
			bottom: -6px;
			height: 1px;
			background: #fff;
			left: 0;
			right: 100%; 
			transition: .2s ease all; 
			width: 0%;
		}
		&:hover:before  {
			right: 0;
			width: 100%;
		}

	}


	.button { 
		@extend .hvr-sweep-to-right; 
		font-style: normal;
		// font-size: 80% ;
		margin-top: 1.5em; 
		width: 100%;
		max-width: 220px;
		text-align: center;
		margin: 1.5em auto  ;
		display: inline-block;
		@include media($desktop) {
			padding: inherit;
			padding: 5px; 
			display: block;
			margin: 1.5em 0;
			max-width: 240px;
		}
		&:hover {
			// background-color: #fff;
			// color: #000;
			// box-shadow: $glow; 
			text-decoration: none;
			border: 1px solid #fff;
		}
		&:active , &:focus {
			color: #fff;
			&:before {
				background-color: $medium-gray ;
			}
		}
	}
}     


footer .connect p {
	font-size: 1.25em;
	@include media(min-width 1679px max-width 1681px) {
		font-size: 1.1em;
	}
	font-family: $heading-font-family-italic-cuts ;
	font-style: initial;

	span {position: relative;}

	span:hover {
		color: #fff;
	}

	span:before { // Underline 
		position: absolute;
		content: '';
		bottom: -6px;
		height: 1px;
		background: #fff;
		left: 0;
		right: 100%; 
		transition: .2s ease all; 
		width: 0%;
	}
	span:hover:before  {
		right: 0;
		width: 100%;
	}


}

footer .connect object, 
footer .connect img {
	max-width: 40px;
}

footer .connect a:nth-of-type(1) {
	margin-right: 1em;
	@include media($medium-screen) {
		margin-right: 0;
	}
}

footer .colophon.container {

	@include clearfix();

	font-family: $base-font-family;
	text-align: center; 
	margin-bottom: 0;
	padding: 0 0 2em;

	@include media($medium-screen) {
		// min-height: 200px;
		padding: 2em 0;
	}

	a, span {
		font-size: .9em;
		line-height: 1.25em;
		display: inline-block;
		margin: 0 .5em;
	}

	> .logo {
		@include outer-container($small-screen);

		img {
			display: block;
			max-height: 45px;
			margin: 1.25em auto ;
			@include media($desktop) {
				max-height: 60px;
				margin: 2em auto 2em;
			}
			@include media($hires) {
				max-height: 70px;
				margin: 2em auto 2em;
			}
		}
	}
}