$_form-background-color: #fff;
$_form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$_form-box-shadow-focus: $_form-box-shadow, 0 0 5px rgba($action-color, 0.7);

fieldset {
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0;
}

legend {
	font-weight: 600;
	margin-bottom: $small-spacing / 2;
	padding: 0;
}

label {
	display: block;
	font-weight: 600;
	margin-bottom: $small-spacing / 2;
}

input,
select,
textarea {
	display: block;
	font-family: $text-font-family;
	font-size: 1.5em;
}

#{$all-text-inputs} {
	appearance: none;
	background-color: $_form-background-color;
	border: $base-border;
	border-radius: $base-border-radius;
	box-shadow: $_form-box-shadow;
	box-sizing: border-box;
	margin-bottom: $small-spacing;
	padding: $base-spacing / 3;
	transition: border-color $base-duration $base-timing;
	width: 100%;

	&:hover {
		border-color: shade($base-border-color, 20%);
	}

	&:focus {
		border-color: $action-color;
		box-shadow: $_form-box-shadow-focus;
		outline: none;
	}

	&:disabled {
		background-color: shade($_form-background-color, 5%);
		cursor: not-allowed;

		&:hover {
			border: $base-border;
		}
	}

	&::placeholder {
		color: tint($base-font-color, 40%);
	}
}

textarea {
	resize: vertical;
}

[type="checkbox"],
[type="radio"] {
	display: inline;
	margin-right: $small-spacing / 2;
}

[type="file"] {
	margin-bottom: $small-spacing;
	width: 100%;
}

select {
	margin-bottom: $small-spacing;
	width: 100%;
}

[type="checkbox"],
[type="radio"],
[type="file"],
select {
	-webkit-appearance:none;
	&:focus {
		outline: $focus-outline;
		outline-offset: $focus-outline-offset;
	}
}


.two-col.has-form {
	font-family: $heading-font-family;
	p {
		font-style: initial; 
		font-family: $heading-font-family-italic-cuts ;
		font-size: 1.3em;
		line-height: 1.75em;
		@include media($medium-screen) {
			font-size: 1.5em;
			line-height: 1.75em;
		}
	}
}


.two-col form fieldset label {
	display: block; 
	max-width: calc( 100% - 10px ) ;
	border: 4px solid #fff ; 
	position: relative;
	margin-bottom: 1.5em;
	// padding: 1em; 
	box-sizing: content-box; 
	outline: 1px solid rgba($medium-gray , .5) ;
	@include media($medium-screen) {
		&:not(.text) { 
			display: flex;
			align-items: center; 
		}
	}

	&.selected-child {
		border: 4px solid rgba($medium-gray , .15)  ;
		// border: 1px solid #fff  ;
		outline: 0px  ;
		max-width: calc(100%) ;
		// margin-left: 1.5%;
	}

	span {
		display: inline-block;
		min-width: 4;
		padding-left: 1em;
		// position: absolute;
		// left: 1em;
		// top: .85em;
		color: #747474  ;
		font-size: .75em;
		font-family: $base-font-family;
		font-weight: bold;
		padding: .5em .5em 0 ;
		position: absolute;
		top: 1.2em;
		@include media($medium-screen) {
			position: static;
			padding: 1em .5em 1em 1.5em;
			
		}

		&.select2-selection__rendered {
			font-weight: 400;
		}
	}

	select, input , textarea {

		margin:0 ;
		height: 100%;
		display: inline-block;
		min-height: 3.5em;
		border: 0;
		box-shadow: 0 0 0;
		padding : .25em 1em;
		resize: none ;
		font-family: $heading-font-family;
		font-size: 1.015em;
		padding-left: 2em;
		background: #fff; 
		outline: 0;
		&::placeholder {
			color: $medium-gray;
		}

		@include media($medium-screen) {
			padding : 0;
		}
		&:focus , &:active {
			box-shadow: 0 0 0;
			border: 0;
		}
	}


	input:-webkit-autofill {
		    -webkit-box-shadow: 0 0 0 30px white inset;
	}

	textarea {
		min-height: 8em;
		@include media($medium-screen) {
			min-height: 16em;
			padding:  .80em 0;
			max-width: 85%;
			vertical-align: top;
		}
	}

	&.select span {
		top: 1.25em;
		
		@include media($medium-screen) {
			// padding-top: 1.25em;
		}
	}

	&.text span {
		top: 0;
		@include media($medium-screen) {
			padding-top: 1.25em;
		}
	}
}

.two-col {
	button { 
		letter-spacing: 1.5px;
		max-width: 100%;
		width: 100%;
		margin-top: 20px;
		@include media($medium-screen) {
			max-width: none ;
			width: auto ;
			margin: 0;
			padding: 1.4em 0;
		}
		transition: .5s ease all; 
		@extend .hvr-sweep-to-right; 
		font-size: 1.015em;
		&:hover {color: #fff; background-color: transparent;}
		&:hover:before {
			background-color: $btn-gray;
		}
	}
}


/*form.with-numbers label {
	position: relative;
	span {
		position: absolute;
		left: 1em;
		color: #747474  ;
		font-size: 0.8em;
		font-family: $base-font-family;
		font-weight: normal;
		padding-top:0.5em;
		position: absolute;
		//top: 1.2em;
		z-index:1;
		@include media($medium-screen) {
			position: absolute;
			padding: .85em .5em 1em 1.15em ;
			
		}


		&#placeholder {		
			left: 5.2em;		
			//transform: scale(1.1);	
			font-weight: 500;		
			transition: all 200ms ease;
			margin:0;
			top:0.8em;
			font-size:1em;

			&.active-focus-filled {		
				//transform: scale(1);
				font-size:0.8em;		
				left: 2.8em;
				font-weight:normal;		
    			// top: 0.2em;		
				top: 0;		

				+ input, + select {
					padding-top: 2em;
				}
			}		
		}
	}
	span + input, span + select, span + textarea  {
		padding-left: 50px;
	}
}*/


form.with-numbers.summary-form {

	label {
		position: relative;
	}
	input , textarea  {
		padding-left: 18px;
	}
	textarea {
		//padding-top: 2.5em ;
		min-height: 75px; 
		color: #747474 ;
		font-size: 14px;
		font-family: $base-font-family;
	}
}
$error: #DD1F52; 
form.with-numbers.summary-form#terms-form {
	span {
		padding-left:0px;
		color: #000;
		left: 0;
	    top: -.6em;
    	width: 80%;
    	font-weight:normal;
		margin-bottom:20px;
	}
	

	#terms-error, #booking-error {
		//font-size: .75em;
		padding-top: 10px;
		color: $error ;
		position:relative;
		//top:30px;
		text-transform:none;
		font-weight:700;
		font-family: $base-font-family;
	}

	#booking-error {
		display:none;
	}
}


 

 
form label.has-error, form label div.has-error {
	> span {
		// color: $error;
	}

	input.error, select.error {
		border: 1px solid $error; 
		margin-bottom: 0;
	}

	span.error {
		position: static;
		display: block;
		margin-bottom: 0;
		color: $error ;
		// position: relative;
		// left: -10px;
	}

}



.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
    .sidebar & {padding-top: 5px !important;}
    :not(.sidebar) & {
    	// padding: 1em; 
    	//padding-left: 1em;
    }
}

.select2 {
	padding: 0 !important; 
	> span {
		// width: 100%; 
		// padding: 0 .5em 1em 1.5em!important ;
		margin: 0 !important; 
	}
}




.select2-container--default .select2-selection--single {
	padding: 0; 
	border: 0;
	border-radius: 0;
	:not(.sidebar) & {
		// padding: .5em;
	}
}

.selection {
	padding: 0 !important ;
}

span.selection {
    width: 95%;
}

.select2-container--default .select2-selection--single .select2-selection__rendered ,
.select2-selection__rendered {
	font-size: 13px; 
	font-family: $heading-font-family; 
	color: #000 ; 
	padding: 0;
}

.sidebarred {
	z-index: 5001;
}

.sidebarred span.select2-dropdown.select2-dropdown--below {
    @include media($medium-screen) {
    	left: -38px;
    	width: 547px !important;
    }
}

span.select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
    outline: none !important ;
}

.select2-results__option  {
    margin-bottom: 0; 
    font-family: $heading-font-family; 
    padding: 1em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $medium-gray ;
    color: #fff;
}


.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    // top: 17px;
    right: 1px;
    width: 20px;
    background: transparent url(../assets/images/icon-customise-select.png) no-repeat;
    margin-bottom: 0; 
    background-position: center 6px;
}


.finalise-booking  {

	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
	    -webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	    margin: 0; 
	}

	input[type=number]::-webkit-inner-spin-button { 
		display: none; 
	   	-moz-appearance:textfield;
	}


}