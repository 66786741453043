@font-face {font-family: 'AvenirNextLTPro-Demi';src: url('fonts/35A3B7_0_0.eot');src: url('fonts/35A3B7_0_0.eot?#iefix') format('embedded-opentype'),url('fonts/35A3B7_0_0.woff2') format('woff2'),url('fonts/35A3B7_0_0.woff') format('woff'),url('fonts/35A3B7_0_0.ttf') format('truetype');}

@font-face {font-family: 'AvenirNextLTPro-Regular';src: url('fonts/35A3B7_1_0.eot');src: url('fonts/35A3B7_1_0.eot?#iefix') format('embedded-opentype'),url('fonts/35A3B7_1_0.woff2') format('woff2'),url('fonts/35A3B7_1_0.woff') format('woff'),url('fonts/35A3B7_1_0.ttf') format('truetype');}

@font-face {
    font-family: 'Romain Headline';
    src: url('fonts/RomainHeadline-Regular-WebS.eot');
    src: url('fonts/RomainHeadline-Regular-WebS.eot?#iefix') format('embedded-opentype'),
    url('fonts/RomainHeadline-Regular-WebS.woff2') format('woff2'),
    url('fonts/RomainHeadline-Regular-WebS.woff') format('woff'),
    url('fonts/RomainHeadline-Regular-WebS.ttf') format('truetype');
}

@font-face {
    font-family: 'Romain Headline Italic';
    src: url('fonts/RomainText-RegularItalic-WebS.eot');
    src: url('fonts/RomainText-RegularItalic-WebS.eot?#iefix') format('embedded-opentype'),
    url('fonts/RomainText-RegularItalic-WebS.woff2') format('woff2'),
    url('fonts/RomainText-RegularItalic-WebS.woff') format('woff'),
    url('fonts/RomainText-RegularItalic-WebS.ttf') format('truetype');
}

@font-face {
    font-family: 'Romain Headline Italic Cuts';
    src: url('fonts/RomainHeadline-RegularItalic-WebS.eot');
    src: url('fonts/RomainHeadline-RegularItalic-WebS.eot?#iefix') format('embedded-opentype'),
    url('fonts/RomainHeadline-RegularItalic-WebS.woff2') format('woff2'),
    url('fonts/RomainHeadline-RegularItalic-WebS.woff') format('woff'),
    url('fonts/RomainHeadline-RegularItalic-WebS.ttf') format('truetype');
}

@font-face {
    font-family: 'Romain Headline Bold';
    src: url('fonts/RomainHeadline-Bold-WebS.eot');
    src: url('fonts/RomainHeadline-Bold-WebS.eot?#iefix') format('embedded-opentype'),
    url('fonts/RomainHeadline-Bold-WebS.woff2') format('woff2'),
    url('fonts/RomainHeadline-Bold-WebS.woff') format('woff'),
    url('fonts/RomainHeadline-Bold-WebS.ttf') format('truetype');
}

@font-face {
    font-family: 'Romain Text';
    src: url('fonts/RomainText-Regular-WebS.eot');
    src: url('fonts/RomainText-Regular-WebS.eot?#iefix') format('embedded-opentype'),
    url('fonts/RomainText-Regular-WebS.woff2') format('woff2'),
    url('fonts/RomainText-Regular-WebS.woff') format('woff'),
    url('fonts/RomainText-Regular-WebS.ttf') format('truetype');
}


// Typography
$base-font-family: "AvenirNextLTPro-Regular" , sans-serif ;
$base-font-family-bold: "AvenirNextLTPro-Demi" , sans-serif ;
$heading-font-family: 'Romain Headline' , serif ;
$heading-font-family-italic: 'Romain Headline Italic' , serif;
$heading-font-family-italic-cuts: 'Romain Headline Italic Cuts' , serif;
$text-font-family: 'Romain Text' , serif;


// Line height
$base-line-height: 1.85 ;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0px;
$base-spacing: 1.5em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

$heading-large: 2.935em;

// Colors
$blue: #1565c0;
$dark-gray: #333;
$btn-gray: #1A1919 ;
$medium-gray: #383534;
$transparent-gray: rgba(56, 54, 52, 0.85);
$transparent-black: rgba(0, 0, 0, 0.75);
$light-gray: #F4F4F4;
$hightlight-gray: #9E9FA7;
$error-color: #E0384B;

$offwhite: #FCFAED ;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $dark-gray;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$viewport-background-color: #fff;

// Focus
$focus-outline-color: transparentize($action-color, 0.4);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;

// Hover

$glow: 0 0 8px rgba(0, 0, 0, 0.6) ;

// Animations
$base-duration: 150ms;
$base-timing: ease;
$base-animation: $base-duration $base-timing all;

// Shadows

$text-shadow: 5px 5px 12px rgba(0,0,0,0.35);

// Screen Size

$small-screen: 350px;
$medium-screen: 675px;
$large-screen: 900px;
$desktop: 1279px;
$hires: 1440px;
$higheres: 1600px;
$qhd: 2560px;

$intermediate: "(min-width: 350px) and (max-width: 900px)";
$mobile-screen: "max-width: 680px";



// Container

$max-width: 1120px ; 