.comiseo-daterangepicker-right.comiseo-daterangepicker:after {
	display: none;
	overflow: scroll;
	max-height: 85vh;
	transition: .2s ease all; 
	left: 0 !important; 
	@include media($desktop) {
	 	content: ' ';
	 	display: block;
	 }
	background: url('images/shape-calendar-arrow.png') no-repeat top center;
	height: 50px;
	width: 150px;
	position: absolute;
	bottom: -49px;
	left: 0;
	right: 0;
	margin: 0 auto; 
}

.ui-widget-header .ui-icon {
	background:none;
}

.comiseo-daterangepicker {
	position: fixed;
	z-index: 12500;
    background-color: rgba(255,255,255,.95);
    border: 0px;
    padding:0;
    border-radius: 0;
    width: auto;
    top: -1em !important;
    left: 1em !important;
    right: 1em;
    bottom: -1em;
    overflow-y: scroll;
    @include media($desktop) {
	    overflow-y: auto;
    	left: 2em;
		max-width: 55%; 
    	transform: translateY(-21vh) ;
    	left: 6.5vw !important ;
    	top:auto !important; 
    	bottom: -80px !important; 
    	right: auto;
    	max-width: 1020px; 
    	// top: -120px; 
    }

    .ui-widget-content {
    	background-color: transparent; 
    }

	.comiseo-daterangepicker-presets {
		display: none;
	}
	.ui-widget-content {
		border: 0; 
	}
}

.ui-datepicker-inline {
    width: 100% !important;
}

.comiseo-daterangepicker-main.ui-widget-content {
	padding: .5em ;
    @include media($desktop) {
    	padding: 2em 4em 1em;
    }
}


table.ui-datepicker-calendar {  
	.ui-button.ui-state-active:hover, .ui-button:active, .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, 
	.ui-state-highlight, .ui-widget-content .ui-state-highlight ,
	.ui-state-highlight, 
	.ui-widget-content 
	.ui-state-highlight, 
	.ui-widget-header .ui-state-highlight {
	    border: 1px solid $medium-gray;
	    background:  rgba(255,255,255,.95);
	    color: #000; 
	    // border: 1px solid #000;
	    > a {
	    	background-color: $medium-gray;
	    	color: #fff;
	    	border-color: $medium-gray;
	    }
	}

	tr .ui-state-highlight > a.state-default {
		color: #000;
	}

}

.comiseo-daterangepicker-calendar .ui-state-highlight a.ui-state-default {
    // color: #000;
}


.ui-datepicker-group.ui-datepicker-group-last ,
.ui-datepicker-group.ui-datepicker-group-first {
	width: 100%;
}


.comiseo-daterangepicker , 
.calendar {
	@include clearfix();
	margin: 2em auto 2em;
	overflow: hidden;

	.hidden-fields {
		display: none;
	}

	.ui-widget.ui-widget-content {
		border: 0;
		margin: 0; 
		padding: 0;
	}

	.hasDatepicker {
		max-width: 95%;
		margin: 0 auto ; 
		@include media($medium-screen) {
		    max-width: 880px;
			display: table;

			.ui-datepicker-row-break {
				display: none;
			}

			.ui-datepicker-group {
				width: 100%; 
				float: none; 
				
				@include media($desktop) {
					width: 45%; 
					margin-left: 5%;
					float:left ;
					margin-bottom: 0;
				}
				&:first-child {
					@include media($desktop) {
						margin-right: 2.5%; 
						margin-left: 0;
						// float: right;
					}
				}
			}

		}
	}
}

.comiseo-daterangepicker ,
.calendar {

	.ui-widget.ui-widget-content {
		position: relative;
	}

	.ui-datepicker .ui-datepicker-header {
		background-color: transparent;
		border: 0;
		border-bottom: 1px solid $medium-gray; 
		border-radius: 0;
			@include media($large-screen) {
				position: static;
			}
		.ui-datepicker-title {
			font-family: $heading-font-family-italic ;
			margin-bottom: .5em;
			font-size: 22px;
		}

		.ui-datepicker-next,
		.ui-datepicker-prev {
			margin-top: 10px;
			background: url('images/icon-chevron-circle.svg') no-repeat top center;

			@include media($large-screen) {
				width: 50px;
				height: 50px;
				position: absolute;
				left: -65px ;
				top: 150px; 
			}

		}
		.ui-datepicker-next {
			transform: rotateZ(180deg);
			
			&.ui-state-hover {
				transform: rotateZ(180deg) scale(1.05);
			}
			
			@include media($large-screen) {
				left: auto;
				right: -45px;
			}
		}

	}
}

.comiseo-daterangepicker .comiseo-daterangepicker-calendar {
	tr td a, tr td span {

		padding: .45em .25em .55em;
		@include media($medium-screen) {
			padding: 0.35em .25em;
		}
	}
}


.comiseo-daterangepicker {
	.ui-datepicker-today.dp-highlight {
		a {
			color: #fff;
		}
	}
}

.calendar .ui-datepicker-group { 
	.ui-datepicker-today.dp-highlight {
		a {
			color: #fff;
		}
	}
}

.comiseo-daterangepicker {
	background-color:RGBA(238,238,238,0.95) !important;
box-shadow:2px 2px 4px 2px RGBA(0,0,0,0.2);
	td.dp-highlight {
		background-color:RGBA(255,255,255,0);
	}
}

.comiseo-daterangepicker ,
.calendar .ui-datepicker-group {

	margin-bottom: 30px;
	

	tr {
		border:0;
	}

	tr td a {
		text-align: center; 
		padding: 0.35em .25em;
		background: #fff; 
		font-family: $heading-font-family; 
	    margin: 2px 0px;
	}

	.dp-highlight {
		border-color: #fff ;
		& a {
			background: $btn-gray; 
			color: #fff;
			border-color: #fff ;
			min-height:48px;
		}

		&.depart a, &.arrive a {
			background: $hightlight-gray;
			padding: .25em .25em 0em 0.25em;
			border-color: $hightlight-gray;
		}

		&.depart a:after {
			content:'DEPART';
		}

		&.arrive a:after {
			content:'ARRIVE';
		}

		&.depart a:after, &.arrive a:after {
			display: block;
			font-size: 9px;
			font-family: $base-font-family-bold; 
			margin-top: -9px;
			
		}
	}

	.ui-datepicker-today {
		& a {
			// background: #eee;
			// border-color: #000;
		}
	}

	.ui-datepicker-unavailable {
		& a {
			color: #eee;
			background: #fff;
			border-color: #9E9FA7;
		}
	}

	.ui-datepicker-current-day {

		&.dp-highlight {

			&.depart a, &.arrive a {
				background: $hightlight-gray;
			}
			
			a { 

				background-color: $btn-gray ;
				color:#fff;
		  	}
		}
		&.dp-highlight.depart {
			a {
				background: $hightlight-gray;
			}
			
		}
		a { background-color: #fff ; }

	}


}

.ui-state-default, .ui-widget-content .ui-state-default {
	background-color: #fff; 
}

.ui-datepicker-calendar th > span {
	text-transform: uppercase;
	font-family: $base-font-family-bold;
	font-weight: 400;
}

.comiseo-daterangepicker tr td {
	border: 2px solid transparent; 
	// height: 50px;
	// padding: 0 0 .5em 0;
	// margin: 0;
	// padding: 0; 
}

.comiseo-daterangepicker tr td a ,
.comiseo-daterangepicker-calendar .ui-state-highlight a.ui-state-default {
	// padding: .25em .25em 1.25em .25em ; 
	// padding:0 ;
}

 .comiseo-daterangepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-prev ,
.comiseo-daterangepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
	width: 30px;
	height: 30px; 
	top: 50%;
	margin-top: -15px;
	@include media($large-screen) {
	    margin-top: 35px; 
    }
	
}

.comiseo-daterangepicker-right, .comiseo-daterangepicker-left {
	.comiseo-daterangepicker-buttonpanel {
		float: none;
	    display: block;
	    position: absolute;
	    top: 15px;
	    right: 10px;
	    margin: 0 auto;
	    max-width: 80%;
	    text-align: center;
	    > button {
	    	margin-bottom: 0; 
	    }

	    @include media($large-screen) {
		    max-width: 50%;
	    }
	}
}

 .booking-form>form>fieldset>label.dates > button.comiseo-daterangepicker-triggerbutton {
    font-size: 18px;
    margin-right: 0;
    text-align: center;
    padding-left: 1em;
    display: block;
    width: 100%;
    margin-top: 7px;
    max-width: 100%; 
    &:focus {
		box-shadow: none;
		outline: none;
	}
}


.ui-datepicker td {
	padding: 1px 3px;
}



body:not(.booking) {
	.ui-widget button {

		background: url('../assets/images/icon-booking-calendar-close.svg') no-repeat top center;
		border: 0;
		border-radius: 0;

		opacity: 1;
		font-family: AvenirNextLTPro-Regular,sans-serif;
		font-size: 20px;
		letter-spacing: 1px;
		
		&.ui-priority-primary {
			background: $medium-gray;
			color: #fff;
			border-color: $medium-gray;
		}
	}
	
	@include media($large-screen) {
		.comiseo-daterangepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
			left: -36px;
		}
		.comiseo-daterangepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
			right: -24px;
		}
	}
	
	.ui-datepicker td {

		a, span {
			font-size: 12px;
			@include media($large-screen) {
				font-size: 18px;
			}
		}
	}
	
	.ui-corner-all.ui-state-hover {
		background: transparent;
	}
}