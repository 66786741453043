.wide-image {
	max-width: 100vw; 
	display: block;
	margin-bottom: 0;
	position: relative;
	transition: .2s ease all;
	overflow: visible;
}

.wide-image picture img ,
.wide-image picture {
	transition: .2s ease height; 
	width: 100%;
	height: auto; 
	display: block; 
	max-width: 100%;
	max-height: 100%;
}

 
.wide-image .title {
	position: absolute;
	color: #fff;
	text-align: center;
	left: 0;
	right: 0;
	text-shadow: $text-shadow; 
	// top: 0%;
	bottom: 8%; 
	font-size: 1.5em;
	@include media($large-screen) {
		top: auto;
		bottom: 10%;
		font-size: 2.5em;
	}
	
	h2 {
		font-size: 1.6em;
		@include media($large-screen) {
			font-size: modular-scale(1);
		}
	}
}

.page-contact .wide-image .title {
	@include media($large-screen) {
		font-size : 2.825em;
	}
}

.page-contact .wide-image .title{
	top: 35%;
	@include media($large-screen) {
		top: 45%;
		padding-top: 0;
		padding-bottom: 0;
	}
}