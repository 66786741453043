.full-screen {

	/*Center horizontaly ie */
	// -ms-flex-pack: center;
	// justify-content: flex-end; 
	width: 100%;
	min-height: calc(100vh) !important ;
	position: relative;
	text-align: center;
	display: flex; 
	// flex-direction: column;
	background-position: center center; 
	background-size: auto 100% ;
	align-items: center ; 
	// padding: 12vh 0 6vh;
		@include media($medium-screen) {
			// padding: 33vh 0 12vh ;
			height: 100vh;
			background-size: cover;
		}
 
	.container {
		position: relative;
		z-index: 10;
		// margin-top: -2em;
		padding: 5em 1.5em;
		text-align: center; 
		@include media($medium-screen) {
			max-width: 728px; 
			padding: 0;
		}		
		@include media($large-screen) {
			margin-top: 1vw;
		}		
		@include media($desktop) {
			// max-width: 728px; 
			// padding: 0;
			margin-top: 0;
		}
	}

	.container picture ,
	.container img {
		display: block;
		width: 100%;
		height: auto; 
		margin: 0 auto 4em;
		max-width: 200px;
		@include media($medium-screen) {
			margin: 0 auto 4.5em;
			// min-width: 650px;
			max-width: 370px;
		}
		@include media($large-screen) {
			margin: 0 auto 3.5em;
			// min-width: 650px;
			// max-width: 370px;
		}
	}

	&#home {
		.container img,
		.container picture {
			@include media(max-width 420px) {
				max-width: 260px;
			}
		}
	}
	&#location {
		.container img,
		.container picture {
			@include media(min-width 1679px max-width 1681px) {
				max-width: 260px;
			}
		}
	}

}


.header .social , 
.thermometer ,  
.full-screen:first-of-type .container img {
	animation: fadein 0.5s normal forwards ease-in ;

}



.full-screen.fx-zoom .container {
	@include media($medium-screen) {
		transform: scale(.85);
	}
	transition: .5s ease all;
	transition-delay: .25s; 
}

.full-screen.fx-zoom.scrolled-to .container {
		// font-size: 60px;
		transform: scale(1);
	
}

.full-screen:first-child > .container {
	padding: 0; 
	max-width: 100%;
	width: 100%;
	@include media($medium-screen) {
		max-width: $medium-screen;
		img, picture {
			max-width: 385px;	
		}
	}	
	@include media($hires + 1px) {
		max-width: $medium-screen;
		img, picture {
			max-width: 550px;	
		}
	}
}


.full-screen#home > .container {
	@include media(min-width $hires max-width 1681px) {
		max-width: $medium-screen;
		img, picture {
			max-width: 420px;	
		}
	}	
}


.full-screen#home {
	@include media(max-width 420px) {
		background-attachment: scroll ;
		-webkit-background-size: cover ;
	}
}


section.slider .backgroundImage ,
section.full-screen .backgroundImage { 
    position:absolute;
    top:0;
    left:0;
    z-index:0;
    height:100%;
    width:100%;
    padding: 0; 
    overflow:hidden; 
}
// /* Apply overlay to darken images, works on IE9 */
// section.full-screen .backgroundImage:before {
//     position: absolute; 
//     z-index: 3;
//     display: block; 
//     content: "";
//     top: 0; right: 0; bottom: 0; left: 0;  
//     background: hsla(0,0%,0%,0.6);
// }
/* Position the original and sharp divs, make fullscreen, set background-size to cover */

section.slider .backgroundImage div,
section.full-screen .backgroundImage div {
    position:absolute;
    height:100%;
    width:100%;
    top:0;
    left:0;
    transition: 1s ease all;
    background-size: auto 100% !important;
    @include media($medium-screen) {
    	margin-bottom: 0;
    	background-size:cover!important;
    }
}
/* Make original div sit on top of sharp div */
section.slider .backgroundImage div.original,
section.full-screen .backgroundImage div.original {
    z-index:2!important;

    // filter: blur(25px);
}
/* Make sharp div sit under original div */
section.slider .backgroundImage div.sharp,
section.full-screen .backgroundImage div.sharp {
    z-index:1!important;
}

