.move-down {
	max-width: 90vw ; 
	margin: 0em auto 1em ;
	text-align: left;
	font-weight: bold;
	// padding: 0 1.5em;
	@include media($large-screen) {
		padding: 0 ;
		text-align: left;
		max-width: $max-width; 
	}
}

.move-down a {
	text-transform: uppercase;
	color: #000;
	text-decoration: none;
	font-family: $base-font-family-bold;
	// font-weight: semibold ;
	font-size: 1.2em;
	&:after {
		content: url('images/icon-circle-arrow.svg') ;
		display: inline-block;
		width: 25px;
		vertical-align: -6px ;
		margin-left: 1em;
	}
	&:hover {
		color: $dark-gray;
	}
}