.bg-slider  {
	color: #fff; 
	background: #555;
	margin-bottom: 0;
	@extend .full-screen ;

	.swiper-wrapper, 
	.swiper-wrapper > .slide {
		padding: 0; 
	}

	.swiper-slide {
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slide {
		background-size: cover;
		margin-bottom: 0;
		background-position: center center;  
		text-align: center; 
		align-items: center; 
		display: flex;
		min-height: 100vh;
		height: 101vh;
		max-width: 100%;
		width: 100%; 
		float: left;
		padding: 2.5em;
		@include media($medium-screen) {
			// max-width: 33.25%; 
		}
	}

	.slide > .container {
		padding: 8em 1.5em 12em; 
	}

	// p {
	// 	font-size: 16px; 
	// }

 	.slide img {
		max-width: 220px;
		@include media($medium-screen) {
			min-width: 325px; 
			max-width: 345px;
		}
		margin: 0 auto 4em ;
		transform: scale(0.15);
		transition: .5s ease all; 
	}

	.swiper-slide-active h2  , 
	.swiper-slide-duplicate-active h2 ,
	.swiper-slide-active img , 
	.swiper-slide-duplicate-active img {
		transform: scale(1);
	}
}

.bg-slider .swiper-pagination {
	bottom: 8em; 
	padding-left: 0;
	@include media($small-screen) {
		bottom: 9em; 
	}

	@include media($medium-screen) {
		bottom: 7em;
		padding-left: 0;
	}
	z-index: 500; 

	.swiper-pagination-bullet {
		opacity: 1;
		background: transparent;
		border: 1px solid #fff;
	}

	.swiper-pagination-bullet.swiper-pagination-bullet-active {
		opacity: 1;
		background: #fff;
	}

}

.bg-slider .swiper-pagination-bullet {
	width: 11px;
	height: 11px; 
}

.bg-slider a.next-section {
	padding-bottom: 0;
	@include media($medium-screen) {
		padding-bottom: 1.5em;
	}
}


.bg-slider {

		img {
			max-width: 180px;
			@include media($medium-screen) {
				max-width: 180px;
			}			
			@include media($desktop) {
				max-width: 210px;
			}
		}



}

.bg-slider > .swiper-fullscreen {

	.swiper-slide {
		background-position: center center; 
		@include media(max-width $medium-screen) {
			background-size: cover;
	 	   height: 100vh;
	 	}

	}

	.slide-content {

		h2 {
			margin-bottom: 27px;
		}

		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;

		@include media(max-width $medium-screen) {
			top: 25vh;
			max-width: calc( 80vw );
		}

		@include media($medium-screen) {
			top: unset;
			bottom: 110px;
			max-width: calc( 85vw );
			// left: 120px;

			text-align: left;
		}
	}


	& + .swiper-pagination {

		// DOTS:

		bottom: 20vh;
		@include media($medium-screen) {
			top: unset;
			bottom: 65px;
		}
		padding: 0;
		.swiper-pagination-bullet {
			border-radius: 0;
			transform: rotateZ(45deg);
		}
	}




}
	// ARROWS:	

.full-screen-slider.bg-slider {

	.swiper-button-next,
	.swiper-button-prev { 
			display: none;
			background: url('images/icon-left-chevron-circle.svg') no-repeat center 100% ;
			@include media($medium-screen) {
				top: 40vh;
				pointer-events: auto;
				display: block ;
				padding: 35px;
			}

	}



	.swiper-button-prev {
		transform: rotateZ(0);
	}


	.swiper-button-next {
		transform: rotateZ(180deg);
	}


}