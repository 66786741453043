.location-bar {
	background-color: $offwhite;
	padding: 2.3em 0;
	@include media($large-screen) {
		padding: 3.6em 0;
	}

	.container  {
		display: block;
		@include media($large-screen) {
			padding: 0;
		}
		&:after {
			clear: both;
			content: '';
			float: none;
			display: block;
		}
	}
	p , a{
		margin:0;
		text-decoration: none;
	}

	a.button {
		margin: 0 auto 0; 
		display: block;
		max-width: 300px;
		text-align: center;
		border: 1px solid $medium-gray;
		&:hover {
			color: #fff;
			border: 1px solid $medium-gray;
		}
		&:hover:before {
			background-color: $medium-gray; 
		}
		@include media($large-screen) {
			margin: 0;
		}
	}

	.connect > a {
		display: inline-block;
	}

	h3 {
		text-transform: none;
		// font-style: italic;
		margin-bottom: 1em;
		font-family: $heading-font-family-italic; 
	}

	object {
		max-width: 35px;
		height: 35px;
		margin-right: .5em;
		opacity: 0.7; 
		&:hover {
			opacity: 0.5;
		}
	}


	.container > div {
		padding: 0 0 2em;
		@include media($large-screen) {
			padding: 0 2em 0 0;
			float: left;
			width: 25%;

		}

	}

	.container > div:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
		padding-right: 0;
		a {
			padding: .5em 2.5em;
			@include media($large-screen) {
				float: right;
				padding-top: 1em ;
				padding-bottom: 1em ;
			}
		}
	}

	address {
		font-style: normal; 
		font-size: 1.1em ;
	}

	a:not(.button) {
		font-size: 1.1em ;
	}

}