.full-menu {

	will-change: transform;

	&.open {
		transform: translate3d(0 , 0 , 0);
		position: fixed;
		z-index: 15500;
		box-shadow: 10px 10px 10px rgba(0,0,0,0.5);
		min-height: 101vh;
		overflow: scroll;

		@include media($desktop) {
			padding: 2em 0 0 0;
		}
	}

	background: $medium-gray;
	color: #fff; 
	width: 100vw;
	transform: translate3d(0 , -100% , 0);
	transition: .5s ease all; 
	z-index: 75;
	position: fixed ;
	// overflow-x: hidden;
	overflow-y: auto ; 
	@include clearfix();
	padding: 2em 0 2em 0 ;
	// min-height: 100vh;
	min-height: 80vh;
	max-height: calc( 100% );

	@include media($medium-screen) {
		min-height: 120vh;
		max-height: calc(100% - 120px);
		padding-bottom: 125px;


		> .branding, .menu > ul {
			// opacity: 0;
			display: none;
			// transition: .125s ease all; 
			// display: block;
		}

	}

	

	> nav , div {
		max-width: 65vw;
		text-align: center;
		margin: 0 auto;
		padding: 2em 0 8em;
		@include media($medium-screen) {
			@include outer-container(1220px);
			flex-wrap: wrap;
			display: flex;
			padding: 7.5em 0 2em;
		}
		@include media($large-screen) {
			padding-top: 3em;
			justify-content: space-between;

		}
		img {
			display: inline-block;
			max-height: 13em ;
			width: auto; 
		}

		ul, li {
			list-style-type: none;
		}

	}


	.menu > div {
		width: 100%;
		padding: 0;
		// opacity: 0;
		display: flex ;
		@include media($medium-screen) {
			padding: 1em 2em;
			display: none;

		}
		@include media($desktop) {
			padding: 0 2.25em ;
		}
	}

	.menu {
		@include media($medium-screen) {
			padding-top: 0;
			transition: .5s ease all; 
		}
	}

	ul {
		width: 100%; 
		text-align: left;
		margin: 0 0 0.25em ; 
		// margin-bottom: 1.25em;
		// display: none;
		// padding-left: 10px;
		border-bottom: 1px solid #fff;
		transition: 1.5s ease all;
		
		@include media($medium-screen) {
			margin-bottom: 0;
			border-bottom: 0;
			display: block;
			padding-left: 0;
			float: left; 
			// padding-bottom: 2%; 

		}

		@include media(465px) {
/*
			width: 29%; 
			margin-right: 0;
			margin-bottom: .25em;
			min-height: 8.5em; 
			
			&:not(:nth-child(3n)) {
				margin-right: 6%;
			}
*/
		}

		@include media(max-width 465px) { 
			&:nth-child(3n) {
				margin-right: 0;
			}
		}

		@include media($medium-screen) {
			margin-bottom: 3vw;
			width: 16.5%; 
			padding-right: 0;

			&:not(:last-child) {
			    margin-right: 4%;

			}
		}


/*
		@include media(max-width $desktop) {
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
*/
	}

 	ul li {
		display: block;
		margin-bottom: 0;
		line-height: 2em; 
		font-family: $heading-font-family-italic-cuts; 
		position: relative;
		
		&:not(:first-child) {
			display: none;
			font-family: $base-font-family; 
		}

		@include media($medium-screen) {
			&:not(:first-child) {
				display: block;
			}
			
			border-bottom: 0;
			&:first-child a {
				pointer-events: none;
				font-family: $heading-font-family-italic-cuts; 
				font-size: 1.15em ;
				border-bottom: 1px solid currentColor ;
				padding-bottom: 0em;
				margin-bottom: .85em;
				font-weight: normal;
				transition: .2s ease all; 
			}
		}

		&.social-link.visible {
			// display: inline-block !important ;
			display: inline-block;
		}

		&.social-link:hover  {
			transform: scale(1.1) translateX(3%);
			a {
				&:before {display: none;}
			}
		}

	}

	ul li a {
		display: block; 
		color: #fff; 
		width: 80%;
		text-decoration: none;
		position: relative;
		font-weight: normal;
		text-transform : capitalize ; 
		font-size: 1.15em ;
		line-height: 40px;
		padding-top: 10px;
		@include media($medium-screen) {
			font-size: 1.15em ;
			line-height: 1.5em;
		}
		&:before { // Underline 
			position: absolute;
			content: '';
			bottom: 0;
			height: 1px;
			background: #fff;
			left: 0;
			right: 100%; 
			transition: .2s ease all; 
			width: 0%;
		}
		&:hover:before  {
			@include media($medium-screen) {
				right: 0;
				width: 100%;
			}
		}

		i {
			font-size: 2.5em;
		}

	}
	

	ul li.social-link {
		display: none;
		// display: inline-block ;
		margin-right: .5em;
		&:last-child {
			margin-right: 0;
		}
	}

	@include media(max-width 674px) {
		ul li:not(.secondary) a {
			// margin-right: 2em;
		}
		
		ul li.secondary {
			a {
				padding-top: 0;
				line-height: 35px;
			}
			
			&:last-child {
				margin-bottom: 23px;
			}
		}

		ul li.social-link {
			// display: none;
		}

	}
	
}

.full-menu .menu ul li:first-child .arrow { // Chevron
	will-change: transform;
	content: url('images/icon-nav-chevron.svg');
	width: 16px;
	height: 20px;
	// background: #fca;
	display: block;
	position: absolute;
	right: 0;
	// bottom: calc(80% - 20px) ;
	transform-origin: 100% 100%;
	transition: .2s ease-all;
	bottom: 8px;
	pointer-events: none; 
	@include media($medium-screen) {
		display: none;
	}
}

.full-menu .menu ul.open li:first-child .arrow  {
	bottom: 32px;
	transform: rotateX(180deg);
}

.full-menu .branding {
	display: none;
	text-align: center; 
	visibility: hidden;
	@include media($medium-screen) {
		visibility: visible;
		text-align: center;
		padding: 0;
		width: 100%; 
		margin: 0 auto  2.5vw ;
		// display: block;
		a , img {
			// display: none;
			min-width: 250px;
			max-width: 270px;
			height: auto;
			margin: 0 auto;
		}
	}

	@include media($large-screen) {
		a, img {
			min-width: 260px;
		}
	}

}


.full-menu div:last-child ul:last-child {
	border: 0;
}


.menu-open .social {
	pointer-events: none ;
}

.view-room body {
	margin-top: 5.5em;
}

.full-menu .menu > div {
	transition: .5s ease transform; 
	opacity: 0;

	transform: translateY(-1em);
	&:nth-child(1){
		transition-delay: 1s;
	}
	&:nth-child(2){
		transition-delay: 2s;
	}
	&.ready {
		transform: translateY(0em);
		opacity: 1;
	}
}


.full-menu nav li.social {
	display: flex;
	flex-direction: row;
	flex: 0 0 33%;
	justify-content: space-between;
	align-items: center ;
	> a {
		margin: 0;
		svg, object {
			width: 55%;
		}
		&:hover{
			&:after, &:before {
				display: none;
			}
		}
	}
}

.home.menu-open .frame {
	//max-height: 100vh;
	overflow: hidden;
}

