@mixin overlay($alpha) {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: rgba(#000, $alpha );
	}
}

.overlaid {
	@include overlay(.25);
}