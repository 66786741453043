.gallery-intro {

	background: url('images/gallery-bg.jpg') no-repeat center center ;
	background-size: cover; 
	width: 100%;
	background-color: $offwhite;
	padding: 2em 0;
	@include media($large-screen) {
		padding: 12em 0;
		margin-top: 3em;
	}
	display: flex;
	// min-height: 100vh;
	.container {
		width: 100%;
		max-width: $max-width;
		background : #fff;
		padding:0;
		@include media($large-screen) {
			display: flex;
			align-items: center;
		}

		.button {
			display: block;
			margin: 2.5em auto 0;
			width: 100%;
			text-align: center;
			font-size: 1.01em;
			max-width: 65vw;
			padding: .5em 1.65em;
			border: 1px solid $medium-gray;
			@extend .hvr-sweep-to-right; 

			&:hover {
				color: #fff;
				border: 1px solid $medium-gray;
			}

			&:hover:before {
				width: 100%;
				background: $medium-gray;
			}

			@include media($large-screen) {
				max-width: 100%;
				margin:0;
			}
		}

		.content {
			padding: 4em 3.25em 2.5em;
			width: 100%;
			border-top: 5px solid $light-gray;
			@include media($large-screen) {
				width: 35%;
				border:0;
			}
		}

		h2 {
			border-bottom: 1px solid $medium-gray;
			margin-bottom: 1.25em;
			padding-bottom: .85em;
			@include media($large-screen) {
				font-size: 2em;
				line-height: 1.5em
			}
		}

		.image {
			@include media($large-screen) {
				width: 65%;
				height: 100%;
				display: block;
				float: right;
 
			}
			width: 100%;
			min-height: 450px;
			background-size: cover;
			background-repeat: no-repeat; 
			margin-bottom: 0;

		}
	}
}

 