@import "header" ;
@import "footer" ;
@import "menu" ;
@import "booking" ;
 
@import "booking-process" ;
@import "date-picker" ;
 
@import "pagination" ;
@import "modal" ;

@import "blog" ;
 

body {
	max-width: 100vw;
	overflow-x: hidden ;
}

* + * {
	margin: 0 0 1em;
}

.wrap {
	margin: 0;
}


.frame, section , footer  {
	position: relative;
	transition-property: height, top, width;
	transition-duration: .25s;
	transition-timing-function: ease;
	@include clearfix();
	margin: 0;
}


section {
	background-attachment: fixed; 
	width: 100%; 
	overflow-x: hidden;
}

section > .main ,
section > div  {
	padding: 1.5em; 
	@include media($large-screen) {
		padding: 3em; 
	}
}


.container {
	max-width: 1120px; 
	margin: 0 auto;
}